@charset "UTF-8";
/*** Variables for colors fonts and screens ***/
/* layout */
/* colors */
/* fonts */
@font-face {
  font-family: 'fontello';
  src: url("../../fonts/fontello/fontello.eot");
  src: url("../../fonts/fontello/fontello"), url("../../fonts/fontello/fontello.woff") format("woff"), url("../../fonts/fontello/fontello.ttf") format("opentype"), url("../../fonts/fontello/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*** Misc settings ***/
/* layout */
/* Tablets */
/* Large screens  */
/***@import 'others/_animations.scss';


/*** Pages ***/
/* Homepage */
body {
  font-size: 13px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  line-height: 1.7;
  color: #535353;
}

body #page_wrapper {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
}

.pt-0 {
  padding-top: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.m-130 {
  margin: 130px 0;
}

.m-200 {
  margin: 200px 0;
}

.m-300 {
  margin: 300px 0;
}

.breadcrumb {
  margin: 10px 0;
}

footer .footer__last {
  background: aliceblue;
  padding: 10px 0;
}

.book {
  padding: 100px 50px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 20px;
}

.form-group {
  padding-bottom: 40px;
}

#returnmessage {
  font-size: 14px;
  color: green;
  text-align: center;
}

.book .column h2 {
  font-weight: 700;
  margin: 20px 0;
}

.clouding {
  position: absolute;
  top: 280px;
  z-index: 3;
}

.cloud {
  width: 600px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: -300px;
  left: -100px;
}

#cloud-base {
  top: 90px;
  height: 100px;
  filter: url(#filter-base);
  box-shadow: 200px 170px 19px 40px rgba(255, 255, 255, 0.9);
}

#cloud-back {
  top: 90px;
  height: 30px;
  width: 500px;
  filter: url(#filter-back);
  box-shadow: 200px 200px 10px 40px rgba(215, 215, 215, 0.3);
}

#cloud-mid {
  top: 80px;
  width: 580px;
  height: 35px;
  filter: url(#filter-mid);
  box-shadow: 210px 250px 28px 30px rgba(66, 105, 146, 0.2);
}

#cloud-front {
  top: 70px;
  width: 450px;
  height: 40px;
  left: -20px;
  filter: url(#filter-front);
  box-shadow: 210px 272px 30px 0px rgba(0, 0, 0, 0.4);
}

.sun {
  position: absolute;
  top: 340px;
  left: 250px;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.9;
  box-shadow: 0px 0px 40px 15px white;
  z-index: 4;
}

.ray_box {
  position: absolute;
  margin: auto;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70px;
  -webkit-animation: ray_anim 120s linear infinite;
  animation: ray_anim 120s linear infinite;
}

.ray {
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  margin-left: 10px;
  border-radius: 80% 80% 0 0;
  position: absolute;
  opacity: 0.1;
}

.ray1 {
  height: 170px;
  width: 30px;
  -webkit-transform: rotate(180deg);
  top: -175px;
  left: 15px;
}

.ray2 {
  height: 100px;
  width: 8px;
  -webkit-transform: rotate(220deg);
  top: -90px;
  left: 75px;
}

.ray3 {
  height: 170px;
  width: 50px;
  -webkit-transform: rotate(250deg);
  top: -80px;
  left: 100px;
}

.ray4 {
  height: 120px;
  width: 14px;
  -webkit-transform: rotate(305deg);
  top: 30px;
  left: 100px;
}

.ray5 {
  height: 140px;
  width: 30px;
  -webkit-transform: rotate(-15deg);
  top: 60px;
  left: 40px;
}

.ray6 {
  height: 90px;
  width: 50px;
  -webkit-transform: rotate(30deg);
  top: 60px;
  left: -40px;
}

.ray7 {
  height: 180px;
  width: 10px;
  -webkit-transform: rotate(70deg);
  top: -35px;
  left: -40px;
}

.ray8 {
  height: 120px;
  width: 30px;
  -webkit-transform: rotate(100deg);
  top: -45px;
  left: -90px;
}

.ray9 {
  height: 80px;
  width: 10px;
  -webkit-transform: rotate(120deg);
  top: -65px;
  left: -60px;
}

.ray10 {
  height: 190px;
  width: 23px;
  -webkit-transform: rotate(150deg);
  top: -185px;
  left: -60px;
}

@-webkit-keyframes ray_anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn.btn-rounded.btn-medium {
  padding: 12px 32px;
}

.btn.btn-rounded {
  border-radius: 50px;
}

.btn.btn-transparent-dark-gray {
  background: transparent;
  border-color: #232323;
  color: #232323;
}

.btn.btn-medium {
  font-size: 12px;
  padding: 6px 25px 5px;
}

.btn {
  display: inline-block;
  border: 2px solid transparent;
  color: inherit;
  letter-spacing: .5px;
  line-height: inherit;
  border-radius: 0;
  text-transform: uppercase;
  width: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  white-space: nowrap;
}

.weatherwidget-io {
  position: absolute;
  width: 30%;
  top: -40px;
  z-index: 4;
  left: -10px;
}

.hg-textbox {
  border-top: 1px solid #ddd;
}

@media (max-width: 991px) {
  .weatherwidget-io {
    width: 50%;
    top: 70px;
  }
  .hg-footer {
    padding: 0px 50px !important;
  }
}

@media (max-width: 479px) {
  .weatherwidget-io {
    width: 100%;
    top: 0px;
    left: 40px;
    right: 0;
    margin: auto;
  }
  .footer-widget ul {
    width: 100%;
  }
  .hg-footer {
    padding: 0px 50px !important;
  }
}

.between {
  z-index: 2;
  position: absolute;
  margin-top: -140px;
  width: 100%;
  height: 160px;
  -webkit-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 90%);
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 90%);
  transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
}

.mydivider {
  text-align: center;
  margin: 20px 0;
}

.mydivider img {
  width: 400px;
  height: auto;
}

.split-layout {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 30em) {
  .split-layout {
    flex-direction: row;
    align-items: stretch;
  }
}

.split-layout__item {
  flex: 1;
}

@media screen and (min-width: 30em) {
  .split-layout__item {
    padding-left: 1em;
  }
  .split-layout__item:first-child {
    padding: 0;
  }
}

.split-layout__divider {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (min-width: 30em) {
  .split-layout__divider {
    flex-direction: column;
  }
}

.split-layout__label {
  padding: 1em;
}

.split-layout__rule {
  flex: 1;
  border-style: solid;
  border-color: rgba(66, 65, 65, 0.3);
  border-width: 1px 0 0 0;
}

@media screen and (min-width: 30em) {
  .split-layout__rule {
    border-width: 0 1px 0 0;
  }
}

#weatherWidget .current .currentWeather {
  width: 20%;
}

#footer {
  padding: 70px 0 0 0;
  background-color: #fcfcfc;
  box-shadow: inset 0 20px 50px -20px rgba(0, 0, 0, 0.06);
}

.footer-widget ul {
  margin: 0 0 15px 0;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  float: left;
}

.img-bgsource {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.img-container__bg {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  height: 100%;
}

.img-container__bg img {
  height: 100%;
  width: 100%;
}

.hg-about-content {
  width: 100%;
  margin: 0 auto;
}

.hg-about-container h4 {
  padding: 10px 5px;
  line-height: 26px;
}

.hg-about-container {
  margin-top: 100px;
  text-align: left;
  padding-bottom: 35px;
}

.hg-about-container--content {
  padding: 0px 128px 30px 0px;
}

.hg-icon_section {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#f7f7f8));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #f7f7f8 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #f7f7f8 100%);
}

.hg-about-container h3 {
  font-size: 30px;
}

.hg-reviews-boom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 400px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

#dot-matrix {
  background: url(http://s14.directupload.net/images/111129/44ga9qid.png);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  margin: 0 auto;
}

.hg-reviews-boom h3 {
  position: absolute;
  transform: translate3d(0px, -64.0031px, 0px);
  color: #fff;
  top: 400px;
}

.brace {
  width: auto;
  min-width: 35px;
  padding-bottom: 20px;
  font-size: 2em;
  line-height: 2em;
  position: relative;
  text-align: center;
  vertical-align: middle;
  margin: 0 15px 15px;
  border: none;
  background-color: transparent;
  background-image: radial-gradient(circle at 0 0, rgba(255, 0, 0, 0) 14.5px, red 15.5px, #bcbcbc 19.5px, rgba(188, 188, 188, 0) 20.5px), radial-gradient(circle at 35px 0, rgba(188, 188, 188, 0) 14.5px, #bcbcbc 15.5px, #bcbcbc 19.5px, rgba(188, 188, 188, 0) 20.5px);
  background-size: 35px 20px;
  background-position: center bottom;
  background-repeat: no-repeat;
  text-transform: lowercase;
  font-style: italic;
  color: white;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
  overflow: visible;
}

.brace:before {
  width: 50%;
  border-top: 5px solid white;
  border-left: 1px solid transparent;
  /* play with this until you like the look of it */
  border-top-left-radius: 20% 30px;
  height: 100%;
  content: "";
  box-sizing: border-box;
  margin-top: -5px;
}

.brace:after {
  width: 50%;
  border-top: 5px solid white;
  border-right: 1px solid transparent;
  /* play with this until you like the look of it */
  border-top-right-radius: 20% 30px;
  height: 100%;
  content: "";
  box-sizing: border-box;
  margin-top: -5px;
}

.signature img {
  width: auto;
  height: 80px;
}

#page_wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  z-index: 0;
}

.site-logo {
  width: 100%;
  margin: 0;
  display: table;
  margin: 0 40px;
  position: relative;
}

.useful-links ul li {
  display: block;
  margin: 0 10px;
  position: relative;
  text-decoration: none;
}

.useful-links ul a {
  color: #535353;
}

ul li {
  display: inline-block;
  margin: 0 10px;
  position: relative;
}

/* helpfull clases*/
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-start-x {
  justify-content: flex-start;
}

.flex-center-y {
  align-items: center;
}

.flex-col {
  flex: 1;
}

.flex-center-x {
  justify-content: center;
}

.flex-end-x {
  justify-content: flex-end;
}

.flex-col-end {
  align-self: flex-end;
}

.flex-basis-auto {
  flex-basis: auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.pgb-100 {
  padding-bottom: 100px;
}

.pgt-100 {
  padding-top: 100px;
}

.mgt-60 {
  margin-top: 60px;
}

.mgb-60 {
  margin-bottom: 60px;
}

.mgt-120 {
  margin-top: 120px;
}

.bg-color--white {
  background-color: #fff;
}

.bg-color--grey {
  background-color: #f5f5f5;
}

.overlay--opacity {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #000;
  opacity: .2;
}

.txt-center {
  text-align: center;
}

h1 {
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 20px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

h3 {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
}

h4 {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  font-style: normal;
  margin-bottom: 10px;
}

a:hover {
  color: #7f82f0;
  text-decoration: none;
}

span {
  color: #7f82f0;
}

th, td {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 35px;
}

/* checkout table*/
.hg-booking {
  margin-bottom: 30px;
  border-spacing: 0;
  border-collapse: collapse;
}

.hg-booking th {
  color: #2a2a2a;
  padding: 10px 17px;
  text-align: center;
}

.hg-booking td {
  padding: 40px 17px;
}

.hg-booking__checkin, .hg-booking__checkout, .hg-booking__night {
  /* <= 992px */
}

@media (max-width: 991px) {
  .hg-booking__checkin, .hg-booking__checkout, .hg-booking__night {
    display: none;
  }
}

.hg-booking__delete {
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-booking__delete {
    padding: 10px 2px !important;
  }
}

.hg-booking__qty, .hg-booking__capacity {
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-booking__qty, .hg-booking__capacity {
    padding: 10px 0px !important;
  }
}

.hg_subtotal td {
  text-align: left;
}

.hg_subtotal__value, .hg_grand_total__value {
  float: right;
  color: #2a2a2a;
  font-weight: 700;
  margin: -10px 0;
  padding: 10px 0 0 26px;
  border-left: 1px solid #eee;
  text-align: right;
}

.hg_grand_total td {
  text-align: left;
}

.hg-align-right {
  text-align: right;
}

.hg_tax td, .hg_adv-pay td {
  text-align: left;
}

.hg_tax__value, .hg_adv-pay__value {
  float: right;
  color: #2a2a2a;
  margin: -10px 0;
  padding: 10px 0 0 26px;
  border-left: 1px solid #eee;
  text-align: right;
}

.hg_payment_all label {
  text-align: right;
  float: right;
  position: relative;
}

.delete_cart {
  color: #555;
}

button {
  height: 50px;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0px 30px;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-fullwhite {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  background: none;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.btn-fullblack {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  background: none;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.hb_btn.box-shadow {
  -webkit-box-shadow: 0px 11px 27px 0px rgba(154, 49, 188, 0.33);
  box-shadow: 0px 11px 27px 0px rgba(154, 49, 188, 0.33);
}

.hg-gallery-but {
  width: 100%;
  margin-top: 30px;
  padding: 10px 20px;
  background: white;
  /* <= 992px */
}

@media (max-width: 991px) {
  .hg-gallery-but {
    margin-top: 60px;
  }
}

input[type=text], input[type=email] {
  max-width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  margin-bottom: 15px;
  border: 0px;
  padding: 26px 16px 6px;
}

label {
  color: #969696;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  cursor: text;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  margin-top: 6px;
}

input[type=password] {
  max-width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  margin-bottom: 15px;
  border: 0px !important;
  padding: 26px 16px 6px;
}

textarea {
  border: 0px;
  border-radius: 4px;
  padding: 26px 16px 6px;
  margin-bottom: 15px;
  width: 100%;
}

/* Search Page */
.btn_add_to_cart {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  background: #5AD2D4;
  padding: 10px 25px;
  color: #FFF;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
}

.btn_add_to_cart:hover {
  opacity: 0.8;
}

input[type=checkbox] {
  cursor: pointer;
  text-align: center;
  height: 14px;
  width: 14px;
  min-width: 14px;
  transition: .05s border-color ease-in-out;
  outline: 0 !important;
  -webkit-appearance: none;
  border: 1px solid #333;
  margin-top: 3px;
}

input[type=checkbox]:before {
  font-family: fontAwesome !important;
  content: '';
  display: none;
  color: #5AD2D4;
}

input[type=checkbox]:checked:before {
  margin-top: -5px;
  float: left;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  font: 400 20px/1 dashicons;
  speak: none;
  -webkit-font-smoothing: antialiased;
}

/*** Elements ***/
footer {
  border-top: 1px solid #efefef;
}

.hg-footer {
  padding: 100px 0px;
}

.hg-footer--row {
  text-align: center;
}

.hg-footer .hg-title-block {
  margin: 0 auto;
}

.hg-footer .hg-title-block__title {
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  margin-bottom: 10px;
  margin-top: 10px;
}

.footer-nav {
  margin: 0 0 15px 0;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  float: left;
}

.footer-nav__item {
  /* <= 768px */
}

@media (max-width: 767px) {
  .footer-nav__item {
    display: block;
    margin: 0 10px;
    position: relative;
  }
}

.footer-nav__item a {
  font-size: 14px;
  line-height: 26px;
  color: #000;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.footer-nav__item a:hover {
  color: #7f82f0;
}

.footer-nav__item:first-child a {
  font-weight: 600;
}

.footer-social {
  padding: 0;
  margin-top: 10px;
  left: -15px;
  top: -20px;
  position: relative;
}

.footer-social__item {
  margin: 5px 3px;
}

.footer-social__item a {
  padding: 22px;
  text-align: center;
  display: block;
}

.footer-social__item .fa {
  color: #000;
  font-size: 18px;
}

.hg-gallery {
  padding-bottom: 20px;
  /*inner column */
}

.hg-gallery__photo-column {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-gallery__photo-column {
    padding-left: 0;
    padding-right: 0;
  }
}

/*big picture from first gallery */
.gridGallery--cols3 .gridGallery__item-w2 {
  width: 66.6666%;
  float: left;
  height: 100%;
  /* <= 480px */
  /* 480px - 1200px */
}

@media (max-width: 479px) {
  .gridGallery--cols3 .gridGallery__item-w2 {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .gridGallery--cols3 .gridGallery__item-w2 {
    width: 50%;
  }
}

/*second gallery*/
.gridGallery--cols2 {
  /*small pictures*/
  /*big picture*/
  /*small picture wrapper*/
  /*big picture wrapper*/
}

.gridGallery--cols2 .gridGallery__item {
  width: 50%;
  position: relative;
  float: left;
  /* <= 480px */
  /* 480px - 1200px */
}

@media (max-width: 479px) {
  .gridGallery--cols2 .gridGallery__item {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .gridGallery--cols2 .gridGallery__item {
    width: 50%;
    height: 100%;
  }
}

.gridGallery--cols2 .gridGallery__item-w2 {
  width: 100%;
  float: left;
  position: relative;
  height: 270px;
  /* 480px - 1200px */
}

.gridGallery--cols2 .gridGallery__item-w2 img {
  transition: transform 0.7s linear, -webkit-transform 0.7s linear;
}

@media (min-width: 480px) and (max-width: 991px) {
  .gridGallery--cols2 .gridGallery__item-w2 {
    width: 50%;
    height: 100%;
  }
}

@media screen and (min-width: 991px) and (max-width: 1366px) {
  .gridGallery--cols2 .gridGallery__item-w2 {
    height: 180px;
  }
}

.gridGallery--cols2 .hg-gridGallery__wrapper {
  padding-bottom: 46%;
  /* <= 480px */
  /* 480px - 1200px */
}

@media (max-width: 479px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper {
    padding-bottom: 49%;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper {
    padding-bottom: 69%;
  }
  .gridGallery--cols2 .hg-gridGallery__wrapper .hg-gridGallery__img-container img {
    width: 150%;
  }
}

.gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 {
  padding-bottom: 22%;
  margin-top: 20px;
  /* <= 480px */
  /* 480px - 1200px */
}

@media (max-width: 479px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 {
    margin-top: 15px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1366px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 {
    padding-bottom: 21%;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 {
    padding-bottom: 62%;
  }
}

.gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 .img-container {
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: -200px;
  right: 15px;
  left: 15px;
  /* <= 480px */
  /* <= 992px */
  /* <= 480px */
}

@media (max-width: 479px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 .img-container {
    top: 0px;
  }
}

@media (max-width: 991px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 .img-container {
    top: 0px;
  }
}

.gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 .img-container img {
  width: 100%;
  height: 100%;
  /* <= 480px */
  /* <= 992px */
}

@media (max-width: 479px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 .img-container img {
    width: auto;
    height: 100%;
  }
}

@media (max-width: 991px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 .img-container img {
    width: 110%;
  }
}

@media (max-width: 479px) {
  .gridGallery--cols2 .hg-gridGallery__wrapper.hg-gridGallery__item-h1 .img-container {
    height: 100%;
  }
}

/*first gallery general styles*/
.hg-gridGallery {
  align-self: center;
  margin: auto;
  width: 80%;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-gridGallery {
    height: auto;
    width: 95%;
  }
}

.hg-gridGallery--item {
  position: relative;
  width: 33.3333%;
  float: left;
  height: 100%;
  /* <= 480px */
  /* 480px - 1200px */
}

@media (max-width: 479px) {
  .hg-gridGallery--item {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .hg-gridGallery--item {
    width: 50%;
  }
}

.hg-gridGallery__img-container {
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  /* <= 480px */
}

.hg-gridGallery__img-container img {
  width: 100%;
  height: 100%;
  transition: transform 0.7s linear;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-gridGallery__img-container img {
    width: auto;
    height: 100%;
  }
}

@media (max-width: 479px) {
  .hg-gridGallery__img-container {
    height: 100%;
  }
}

.hg-gridGallery--image_link:hover .hg-icon {
  display: block;
  opacity: 1;
  margin-top: 0;
}

.hg-gridGallery--image_link:hover img {
  transform: scale(1.1);
  transition-duration: 2s;
  transition: transform 0.7s linear;
}

.hg-gridGallery__wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 72%;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-gridGallery__wrapper {
    height: 250px;
    overflow: visible;
    padding: 0px 15px 30px 15px;
  }
}

.hg-gridGallery__wrapper .hg-icon {
  opacity: 0;
  position: absolute;
  top: 50%;
  margin-top: 15px;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .25s ease-in-out;
  border-color: #fff;
  color: #fff;
}

.hg-gridGallery__wrapper .hg-icon__circled-icon {
  width: 56px;
  height: 56px;
  line-height: 56px;
  display: inline-block;
  border-radius: 50%;
  box-sizing: content-box;
  text-align: center;
  border: 2px solid currentColor;
}

.hg-gridGallery__wrapper .hg-icon__circled-icon:before {
  line-height: 56px;
  display: block;
  vertical-align: middle;
}

.hg-gridGallery--arrow {
  width: 28px;
  height: 28px;
  cursor: pointer;
  position: absolute;
  top: 46%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hg-gridGallery--arrow polyline {
  transition: all .15s ease-out;
}

.hg-gridGallery--arrow polyline:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.hg-gridGallery--arrow:hover polyline {
  stroke-width: 35;
}

.arrow--next {
  left: 100%;
  margin-left: 20px;
}

.arrow--prev {
  right: 100%;
  margin-right: 20px;
}

.hg-gallery-container h3 {
  font-size: 30px;
}

.hg-gallery-container h4 {
  padding: 10px 5px;
  line-height: 26px;
}

.hg-gallery {
  background-color: #fff;
}

.hg-gallery--row {
  width: 79%;
  background-color: #efefef;
  height: auto;
  padding-bottom: 50px;
  margin-bottom: 60px;
  /* <= 768px */
}

@media (max-width: 767px) {
  .hg-gallery--row {
    margin-bottom: 100px;
  }
}

.hg-gallery--text-inner-content {
  vertical-align: middle;
  text-align: center;
  height: auto;
  width: 100%;
}

.hg-gallery__but {
  background: white;
  width: 100%;
  margin-top: 30px;
}

.hg-gallery--row-title {
  align-self: center;
  margin: auto;
  width: 79%;
  margin-top: 35px;
  /* <= 992px */
  /* <= 480px */
}

@media (max-width: 991px) {
  .hg-gallery--row-title {
    width: 85%;
  }
}

@media (max-width: 479px) {
  .hg-gallery--row-title {
    width: 100%;
  }
}

.hg-title-block__subtitle {
  font-size: 14px;
  line-height: 26px;
}

.site-header {
  position: absolute;
  width: 100%;
  background: transparent;
  z-index: 3;
  /* <= 768px */
  /* <= 480px */
  /* <= 768px */
}

@media (max-width: 767px) {
  .site-header {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
  }
}

@media (max-width: 479px) {
  .site-header {
    position: relative !important;
  }
}

@media (max-width: 767px) {
  .site-header {
    background: transparent;
  }
  .site-header .site-header-col-left {
    border-right: none !important;
    padding-right: 0px;
    margin: 0 auto;
  }
  .site-header .site-logo {
    padding-top: 40px;
    padding-bottom: 20px;
    display: block;
  }
}

.site-header .siteheader-container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  /* <= 480px */
}

@media (max-width: 479px) {
  .site-header .siteheader-container {
    position: absolute;
  }
}

.site-header .site-header-main {
  /* <= 768px */
}

@media (max-width: 767px) {
  .site-header .site-header-main {
    flex-direction: column;
  }
}

/* >= 768px */
@media (min-width: 768px) {
  .site-header {
    background: transparent;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
  }
  .site-header.site-header--absolute.header--sticky {
    position: fixed;
  }
  .site-header.header--dark {
    background-color: #ffffff;
    border: 2px solid #f9f8f8;
  }
  .site-header.header--dark .main-menu-wrapper .sh__hamburger-trigger span {
    background: white;
  }
  .site-header.header--dark .btn-fullwhite {
    background: none;
    color: white !important;
    transition: 0.1s ease-in-out;
  }
  .site-header.header--dark .btn-fullwhite span {
    color: #3c3a3a;
  }
  .site-header.header--dark .btn-fullblack {
    background: none;
    color: black !important;
    transition: 0.1s ease-in-out;
  }
  .site-header.header--dark .btn-fullblack span {
    color: white;
  }
  .site-header.header--sticky {
    width: 100%;
    z-index: 9;
    will-change: transform;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
  }
  .header--is-sticked {
    /* images tranzition*/
  }
  .header--is-sticked .site-logo-img-sticky {
    opacity: 1 !important;
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .header--is-sticked .site-logo-img {
    opacity: 0 !important;
    transition: opacity .15s ease-in-out;
  }
}

/* images when top bar sticked*/
.header--sticky .site-logo-img-sticky {
  display: block;
  position: absolute;
  top: 50%;
  left: 35%;
  opacity: 0;
  transform: translateX(-50%) translateY(0);
  transition: opacity .15s ease-in-out,transform .15s ease-in-out;
  backface-visibility: hidden;
}

.header--sticky .site-logo-img-sticky + .site-logo-img {
  opacity: 1;
  transition: opacity .15s ease-in-out;
}

.header--not-sticked {
  position: relative;
}

.header--not-sticked .main-menu-wrapper {
  /* <= 768px */
}

@media (max-width: 767px) {
  .header--not-sticked .main-menu-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
  }
}

.site-header-col-left {
  padding-right: 35px;
}

.site-logo-img {
  max-width: 100%;
  width: 196px;
  height: 36px;
}

.site-logo-anch {
  padding: 0;
  display: table-cell;
  position: relative;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  text-transform: none;
}

.menu-wrapper {
  padding-left: 35px;
  padding-top: 23px;
}

.site-logo span {
  white-space: nowrap;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  color: white;
  font-family: Poppins;
  text-transform: uppercase;
  letter-spacing: 2px;
  visibility: inherit;
}

.site-logo.black span {
  color: black !important;
}

#logo-container {
  padding: 15px 0;
}

.main-menu-wrapper {
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  display: inline-flex;
  height: 100%;
  line-height: 65px;
}

.main-menu-wrapper .sh-component:hover {
  text-decoration: none;
}

.main-menu-wrapper .sh-component:focus {
  text-decoration: none;
}

.main-menu-wrapper .sh-component:visited {
  text-decoration: none;
}

.main-menu-wrapper .sh-component {
  margin-left: 20px;
  margin-right: 20px;
  /* <= 768px */
}

.main-menu-wrapper .sh-component span {
  color: #fff;
  white-space: nowrap;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  font-family: Poppins;
  text-transform: uppercase;
  letter-spacing: 2px;
  visibility: inherit;
  /* <= 480px */
}

@media (max-width: 479px) {
  .main-menu-wrapper .sh-component span {
    font-size: 12px;
  }
}

.main-menu-wrapper .sh-component .fa {
  font-size: 18px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .main-menu-wrapper .sh-component {
    margin-left: 0px;
  }
}

/* hamburger icon*/
.sh__hamburger-trigger {
  width: 20px;
  height: 20px;
  opacity: 1;
  margin-right: 0;
  position: relative;
  display: block;
}

.sh__hamburger-trigger span {
  height: 3px;
  background: #fff;
  display: block;
  position: absolute;
  width: 100%;
  border-radius: 2px;
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.sh__hamburger-trigger .onwhite {
  background: #000;
}

.sh__hamburger-trigger span:first-child {
  top: 0;
}

.sh__hamburger-trigger span:nth-child(2) {
  top: 6px;
}

.sh__hamburger-trigger span:nth-child(3) {
  top: 12px;
}

.sh__hamburger-trigger.is-active span:nth-child(1) {
  transform: rotate(135deg);
}

.sh__hamburger-trigger.is-active span:nth-child(2) {
  transform: rotate(-135deg);
}

.sh__hamburger-trigger.is-active span:nth-child(3) {
  transform: rotate(-135deg);
}

/* Menu icon hover state */
.sh__hamburger-trigger:hover span {
  background: #535353;
}

/***  Navigation overlay ***/
.sh__navOverlay {
  background-color: rgba(255, 255, 255, 0.98);
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.sh__navOverlay.is-active {
  transform: translateY(0) translateZ(0);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0ms, -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0ms;
}

.sh__navOverlay--closed {
  transform: translateY(-100%) translateZ(0);
  transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0ms, -webkit-transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0ms;
}

/* close button with animations */
.button-navOverlay-close {
  position: fixed;
  z-index: 2;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  transition: opacity .2s ease-out,-webkit-transform .7s ease-out;
  transition: transform .7s ease-out,opacity .2s ease-out;
  transition: transform .7s ease-out,opacity .2s ease-out,-webkit-transform .7s ease-out;
  opacity: .8;
  right: 30px;
  top: 30px;
}

.button-navOverlay-close span {
  position: relative;
  display: block;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-indent: 100%;
  white-space: nowrap;
}

.content-span, .button-navOverlay-close span:after, .button-navOverlay-close span:before {
  content: "";
  width: 2px;
  height: 0;
  display: block;
  top: 50%;
  left: 50%;
  position: absolute;
  background: #000;
  transition: height .3s ease-out;
}

.button-navOverlay-close span:before {
  transform: translate(-50%, -50%) rotate(45deg);
  transition-delay: .6s;
}

.button-navOverlay-close span:after {
  transform: translate(-50%, -50%) rotate(-45deg);
  transition-delay: .8s;
}

.content-span-close, .sh__navOverlay.is-active .button-navOverlay-close span:before, .sh__navOverlay.is-active .button-navOverlay-close span:after {
  height: 15px;
}

.sh__navOverlay.is-active .button-navOverlay-close {
  transform: rotate(180deg);
}

.sh__navOverlay.is-active .button-navOverlay-close circle {
  stroke-dashoffset: 0;
  stroke-opacity: 1;
}

.button {
  /* circle hover*/
}

.button-navOverlay-close svg {
  position: absolute;
  top: 0;
  left: 0;
}

.button-navOverlay-close circle {
  transition: stroke-dashoffset .6s ease-out .4s,stroke-width .2s ease-out,stroke-opacity .2s ease-out;
  stroke: #000;
  stroke-width: 1;
}

.button-navOverlay-close:hover circle {
  stroke-width: 2;
}

/* navigation overlay menu with animations */
.sh__navOverlay-inner {
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 60px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.sh__navOverlay--menuWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  min-height: calc(100% - 80px);
}

.sh__navOverlay--menuWrapper-inner {
  width: 100%;
  margin-top: 20px;
  padding-top: 60px;
  /* <= 768px */
  /* >= 992px */
}

@media (max-width: 767px) {
  .sh__navOverlay--menuWrapper-inner {
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .sh__navOverlay--menuWrapper-inner {
    min-height: auto;
    padding: 20px 0px 40px 0;
  }
}

.sh__navOverlay--mainNav {
  letter-spacing: 1px;
  text-align: center;
  display: block;
  position: relative;
  padding: 40px 0;
  width: 100%;
}

.sh__navOverlay--mainNav li {
  opacity: 1;
  transform: translateY(0);
  transition: transform .5s ease 0s,opacity .4s ease 0s,-webkit-transform .5s ease 0s;
  position: relative;
  backface-visibility: hidden;
}

.ovr-menu__item {
  display: block;
  text-align: center;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.ovr-menu__item a {
  padding: .17em 0;
  font-size: 2em;
  transition: .2s ease-out;
  transition-delay: 0s;
  color: rgba(18, 18, 18, 0.85);
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.ovr-menu__item a:hover {
  color: #7f82f0;
}

.ovr-menu :first-child {
  transition-delay: .06s;
}

.ovr-menu :first-child a {
  color: #7f82f0;
}

.ovr-menu :nth-child(2) {
  transition-delay: .12s;
}

.ovr-menu :nth-child(3) {
  transition-delay: .18s;
}

.ovr-menu :nth-child(4) {
  transition-delay: .24s;
}

.ovr-menu :nth-child(5) {
  transition-delay: .3s;
}

.ovr-menu :nth-child(6) {
  transition-delay: .36s;
}

.sh__navOverlay--closed .sh__navOverlay--mainNav li {
  opacity: 0;
  position: relative;
  backface-visibility: hidden;
  transform: translateY(-100%);
  transition: transform .25s ease-in 0s,opacity .5s ease 0s,-webkit-transform .25s ease-in 0s;
}

.copyText-wrapper {
  flex: 1 1 auto;
  margin-left: 60px;
  height: 80px;
  /* <= 768px */
}

@media (max-width: 767px) {
  .copyText-wrapper {
    text-align: center;
    margin-left: 0px;
  }
}

.copyText-wrapper .copyText {
  color: black;
  font-size: 1em;
  letter-spacing: 2px;
}

.hg-iconbox {
  margin-bottom: 30px;
}

.hg-icon_section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.hg-content-iconbox {
  width: 90%;
  margin: 0 auto;
}

.hg-icon-bottom {
  padding-top: 35px;
  padding: 0 30px;
}

.hg-icon-bottom .hg-title-block {
  margin-bottom: 55px;
}

.hg-icon-bottom .hg-title-block__title {
  font-size: 30px;
}

.hg-iconbox--center-icon {
  margin-bottom: 30px;
}

.hg-iconbox--center-icon .hg-iconbox__icon-wrapper {
  margin-right: 0;
  float: none;
}

.hg-iconbox--small .hg-iconbox__icon-wrapper {
  margin-top: 18px;
  /* <= 480px */
}

.hg-iconbox--small .hg-iconbox__icon-wrapper .glyphicon {
  font-size: 18px;
}

@media (max-width: 479px) {
  .hg-iconbox--small .hg-iconbox__icon-wrapper {
    margin-bottom: 0px;
  }
}

.hg-iconbox--floating .glyphicon {
  font-size: 12px;
  color: #ffffff;
}

.hg-iconbox--floating .hg-iconbox__icon-wrapper {
  position: relative;
  animation-duration: 2s;
  animation-name: floatingAnim;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes floatingAnim {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, 0px);
  }
}

.hg-wcome-container, .hg-gallery-container, .hg-promotions-container {
  margin-top: 65px;
  text-align: center;
  padding-bottom: 35px;
}

.hg-wcome-container h3, .hg-gallery-container h3, .hg-promotions-container h3 {
  font-size: 30px;
}

.hg-wcome-container h4, .hg-gallery-container h4, .hg-promotions-container h4 {
  padding: 10px 5px;
  line-height: 26px;
}

.hg-wcome-content, .hg-gallery-content, .hg-promotions-content {
  width: 65%;
  margin: 0 auto;
}

.hg-separator {
  margin: 0 auto;
  width: 10%;
  margin-bottom: 35px;
  /* <= 768px */
}

@media (max-width: 767px) {
  .hg-separator {
    width: 30%;
  }
}

.hg-separator--icon-no {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #efefef;
  margin-top: 35px;
  margin-bottom: 50px;
  /* <= 768px */
}

@media (max-width: 767px) {
  .hg-separator--icon-no {
    margin-bottom: 30px;
  }
}

.hg-separator__line {
  border-top-width: 1px;
  border-top-color: #efefef;
  width: calc(50% - 27px);
  border-top-style: solid;
  vertical-align: middle;
}

.hg-separator__line--left {
  position: relative;
  float: left;
}

.hg-separator__line--right {
  position: relative;
  display: inline-block;
  float: right;
}

.hg-separator__icon {
  display: inline-block;
  color: #e2e2e2;
  vertical-align: middle;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -23px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hg-separator__icon:before {
  font-family: 'fontAwesome';
  content: '';
}

@media screen and (min-width: 991px) and (max-width: 1366px) {
  .hg-iconbox__icon-wrapper.boat_wrapper {
    float: left;
    margin-right: 10px;
  }
}

.hg-iconbox__icon-wrapper.bed_wrapper {
  margin-right: 8px;
  /* <= 768px */
}

@media screen and (min-width: 760px) and (max-width: 990px) {
  .hg-iconbox__icon-wrapper.bed_wrapper {
    margin-bottom: 3px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1366px) {
  .hg-iconbox__icon-wrapper.bed_wrapper {
    float: left;
    margin-right: 0px;
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .hg-iconbox__icon-wrapper.bed_wrapper {
    margin-right: 5px;
  }
}

.hg-iconbox__icon-wrapper {
  margin-top: 10px;
  margin-bottom: 15px;
  /* >= 480px */
  /* 768px - 991px */
}

.hg-iconbox__icon-wrapper .bed {
  /* <= 768px */
}

@media (max-width: 767px) {
  .hg-iconbox__icon-wrapper .bed {
    margin-left: -20px;
  }
}

.hg-iconbox__icon-wrapper .boat {
  /* <= 768px */
}

@media (max-width: 767px) {
  .hg-iconbox__icon-wrapper .boat {
    margin-left: -20px;
  }
}

@media (min-width: 480px) {
  .hg-iconbox__icon-wrapper {
    float: left;
    margin-right: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hg-iconbox__icon-wrapper {
    float: none;
    margin-right: 0;
  }
}

.hg-iconbox__icon-wrapper .hg-iconbox__icon {
  font-size: 60px;
  line-height: 1;
  transition: color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-iconbox__icon-wrapper .hg-iconbox__icon {
    font-size: 79px;
  }
}

.hg-iconbox__content-wrapper {
  overflow: hidden;
}

.hg-iconbox__title-wrapper {
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 30px;
  font-style: normal;
}

.hg-iconbox__title-wrapper h3 {
  font-size: 14px;
  font-weight: 700;
}

.hg-iconbox__title-wrapper h3 a {
  color: #000;
  font-weight: 700;
}

.hg-iconbox__title-wrapper h3 a:hover {
  color: #7f82f0;
}

.hg-iconbox__desc-wrapper {
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
}

.hg-iconbox__desc-wrapper p {
  color: #535353;
}

.hg-iconbox:hover .hg-iconbox__icon {
  color: #5AD2D4;
}

.hg-promotions {
  padding-bottom: 120px;
  /* <= 992px */
}

@media (max-width: 991px) {
  .hg-promotions {
    padding-bottom: 60px;
  }
}

.hg-promotions-container h3 {
  font-size: 30px;
}

.hg-promotions-container h4 {
  padding: 10px 5px;
  line-height: 26px;
}

.hg-prom-col {
  padding: 0px 25px;
}

.hg-prom-content {
  margin-bottom: 25px;
}

.hg-prom-content--imgbox {
  height: 321px;
  margin-bottom: 30px;
}

.hg-prom-content--imgbox .image-boxes-img {
  transition: opacity .2s ease-out;
  -webkit-backface-visibility: hidden;
}

.hg-prom-content--imgbox .image-boxes-img:hover {
  opacity: 0.75;
}

.hg-prom-content__wrapper {
  height: 100%;
}

.hg-prom-content__wrapper .imglink {
  height: 100%;
}

.hg-prom-content__wrapper .imglink .image-boxes-img {
  height: 100%;
}

.hg-prom-content__wrapper .imglink:hover {
  transition: opacity .2s ease-out;
}

.hg-prom-content--txt h3 {
  font-size: 18px;
  text-transform: none;
}

.hg-prom-content--txt h4 {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.image-boxes-img {
  width: 100%;
  height: auto;
}

.image-boxes-img .cover-fit-img {
  height: 100%;
}

.cover-fit-img {
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}

.flex-row.form-row {
  /* <= 768px */
}

@media (max-width: 767px) {
  .flex-row.form-row {
    display: block;
  }
}

.hg-form-component-input {
  margin: 0 auto;
  padding: 0 5px;
  margin-bottom: 20px;
}

.hg-form-component-input .input-group.date {
  border-bottom: 2px solid #E5E5E5;
}

.hg-form-component-input .input-group.date:hover {
  border-bottom: 2px solid black;
}

.hg-form-component-input .input-group.date:after {
  position: absolute;
  content: '\f073';
  color: #aaa;
  font: normal normal normal 14px/1 FontAwesome;
  right: 22px;
  top: 12px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .hg-form-component-input .input-group.date:after {
    top: 17px;
    right: 9px;
  }
}

.hg-form-component-input .input-group {
  height: 50px;
  display: inline-flex;
  width: 100%;
}

.hg-form-component-input .input-group input[type=text] {
  border-bottom: 2px solid #E5E5E5;
  border-radius: 0;
}

.hg-form-component-input label {
  display: block;
  color: #222;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  font-size: 13px;
  position: relative;
}

.hg-form-component {
  margin: 0 auto;
  padding: 0 5px;
  margin-bottom: 20px;
  display: flex;
  float: left;
  margin-left: 20px;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-form-component {
    margin-top: 20px;
  }
}

select, .hg-form-component-input .input-group input[type=text] {
  text-overflow: ellipsis;
  font-family: sans-serif;
  background: none;
  width: 100%;
  height: 50px;
  border: 0px;
  border-bottom: 2px solid #E5E5E5;
  min-width: 0;
  padding: 7px 8px;
  font-size: 15px;
  color: #7a7a7a;
  line-height: 24px;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

select:focus, .hg-form-component-input .input-group input:focus[type=text], select:hover, .hg-form-component-input .input-group input:hover[type=text] {
  border-bottom: 2px solid #222;
  border-radius: 0px;
  outline: none;
}

select:visited, .hg-form-component-input .input-group input:visited[type=text], select:active, .hg-form-component-input .input-group input:active[type=text] {
  outline: none;
}

.btn-gradient {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  background: -webkit-gradient(linear, left top, right top, from(#7f82f0), to(#5ad2d4));
  background: -webkit-linear-gradient(left, #7f82f0 0%, #5ad2d4 100%);
  background: linear-gradient(to right, #7f82f0 0%, #5ad2d4 100%);
  -webkit-box-shadow: 0px 8px 27px 0px rgba(126, 117, 130, 0.33);
  box-shadow: 0px 8px 25px 0px rgba(142, 134, 144, 0.33);
  color: #FFF;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: center;
  width: 100%;
  padding: 0px 28px;
}

@media screen and (min-width: 991px) and (max-width: 1366px) {
  .btn-gradient {
    padding: 0px 0px;
  }
}

.btn-gradient:hover {
  opacity: 0.8;
}

.search-container h2 {
  margin-bottom: 10px;
  font-size: 16px;
  padding-top: 10px;
}

.form-container {
  display: block;
  position: relative;
  width: 100%;
  min-height: 0px;
  background-color: #ffffff;
  padding: 35px;
  box-shadow: 0 15px 75px 0 rgba(0, 0, 0, 0.06);
}

.home-container {
  margin-top: -130px;
}

/* date picker style */
.ui-datepicker.ui-widget {
  z-index: 9999;
  display: none;
  position: relative;
  color: #333;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
  border-radius: 0;
  padding: 8px;
}

.ui-datepicker.ui-widget .ui-datepicker-header {
  background: 0 0;
  border: 0;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next, .ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev {
  background: center center no-repeat;
  background-size: 75% 75%;
  border: 0;
  cursor: pointer;
  width: 20px;
  height: 30px;
  opacity: .5;
  filter: alpha(opacity=50);
  transition: all .3s;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next span, .ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev span {
  display: none;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next:hover, .ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev {
  float: left;
  left: 0;
  right: auto;
  top: 4px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==);
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=);
  float: right;
  right: 0;
  top: 4px;
  left: auto;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-title {
  color: #2a2a2a;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-title span {
  color: #2a2a2a;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar {
  border: 0;
  margin-bottom: 24px;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar th {
  color: #999;
  font-size: 12px;
  line-height: 25px;
  font-weight: 700;
  text-align: center;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar th span {
  cursor: help;
  border-bottom: 1px dotted #777;
  color: #999;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td {
  padding: 0;
  border: 0;
  height: 27px;
  overflow: hidden;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td.ui-state-disabled {
  padding: 0;
  border: 0;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td.ui-state-disabled .ui-state-default:hover {
  background: #f5f5f5;
  color: #666;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar .ui-state-default {
  cursor: pointer;
  display: block;
  outline: 0;
  border: 0;
  margin: 0;
  width: 100%;
  padding: 5px;
  color: #666;
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  background: #f5f5f5;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar .ui-state-default:hover {
  color: #fff;
  background: #ffb606;
}

/* searchroom page */
.hg-searchroom {
  overflow: hidden;
}

.hg-searchroom h2 {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
}

.hg-searchroom .btn-gradient {
  width: auto;
  margin-top: 24px;
}

#rev_slider_1_1 .zeus.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
}

#rev_slider_1_1 .zeus.tparrows .tp-leftarrow:before {
  content: "\e824";
}

#rev_slider_1_1 .zeus.tparrows .tp-rightarrow:before {
  content: "\e825";
}

#rev_slider_1_1 .zeus.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: white;
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative;
}

#rev_slider_1_1 .tp-title-wrap {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s;
  border-radius: 50%;
}

#rev_slider_1_1 .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  transform: translatex(-100%);
  transition: all 0.3s;
}

#rev_slider_1_1 .zeus.tp-rightarrow .tp-arr-imgholder {
  transform: translatex(100%);
}

#rev_slider_1_1 .zeus.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0);
  opacity: 1;
}

#rev_slider_1_1 .zeus.tparrows:hover .tp-title-wrap {
  transform: scale(1);
  opacity: 1;
}

.hg-slideshow {
  position: relative;
  top: 0;
  z-index: 0;
  width: 100%;
}

.hg-insta {
  padding: 70px 0px;
  background-color: #f7f7f8;
}

.hg-insta--row {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.hg-insta--row .img-box {
  margin-bottom: 30px;
}

.hg-insta--row p {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #333333;
}

/*gallery*/
.instashow-gallery-media-info {
  display: block;
  position: absolute;
  visibility: hidden;
  z-index: 3;
  width: 80%;
  max-height: 80%;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%) translateY(-40%);
  text-align: center;
  overflow: hidden;
  transition: all .3s ease;
  font-size: 14px;
}

.instashow-gallery-media-info span {
  color: #fff;
}

.hg-slider-media-cover {
  display: block;
  position: absolute;
  visibility: hidden;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all .4s ease;
  background: rgba(126, 130, 240, 0.7);
}

.hg-slider-jstrigger {
  height: 300px;
  overflow: hidden;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-slider-jstrigger {
    height: 63px;
  }
}

@media (max-width: 750px) and (min-width: 491px) {
  .hg-slider-jstrigger {
    height: 99px;
  }
}

@media (max-width: 991px) and (min-width: 750px) {
  .hg-slider-jstrigger {
    height: 150px;
  }
}

@media (max-width: 1500px) and (min-width: 992px) {
  .hg-slider-jstrigger {
    height: 200px;
  }
}

.hg-slider__item {
  width: 20%;
  position: relative;
  float: left;
  height: 100%;
}

.hg-slider--image_link:hover .hg-icon {
  display: block;
  opacity: 1;
  margin-top: 0;
}

.hg-slider--image_link:hover img {
  transform: scale(1.1);
  transition-duration: 2s;
  transition: transform 0.7s linear;
}

.hg-slider--image_link:hover .hg-slider-media-cover {
  visibility: visible;
  opacity: 1;
}

.hg-slider--image_link:hover .instashow-gallery-media-info {
  visibility: visible;
  opacity: 1;
}

.hg-slider__img-container {
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: 0px;
  left: 0px;
}

.hg-slider__img-container img {
  width: 100%;
  left: 50%;
  height: 100%;
  transition: transform 0.7s linear;
}

/*gallery arrows*/
.hg-slider-gallery {
  direction: ltr !important;
}

.hg-slider-gallery--arrow {
  z-index: 10;
  width: 74px;
  height: 74px;
  cursor: pointer;
  position: absolute;
  top: 46%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-slider-gallery--arrow {
    width: 63px;
    height: 63px;
    top: 49%;
    right: 5px;
  }
}

.hg-slider-gallery--arrow:hover {
  background: #fff;
}

.hg-slider-gallery--arrow:before, .hg-slider-gallery--arrow:after {
  background: #5AD2D4 !important;
}

/*next arrow*/
.hg-slider-gallery--arrow.arrow--next:before, .arrow--next.hg-slider-gallery--arrow:after, .hg-slider-gallery--arrow.arrow--next:after {
  display: block;
  position: absolute;
  height: 2px;
  width: 12px;
  transition: all .3s ease;
  content: '';
  top: 36px;
  left: 13px;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-slider-gallery--arrow.arrow--next:before, .arrow--next.hg-slider-gallery--arrow:after, .hg-slider-gallery--arrow.arrow--next:after {
    top: 32px;
    left: 6px;
  }
}

.hg-slider-gallery--arrow.arrow--next {
  right: 0;
  left: auto;
  transform: translate3d(40%, -50%, 0);
}

.hg-slider-gallery--arrow.arrow--next:before, .arrow--next.hg-slider-gallery--arrow:after {
  transform-origin: 100% 110%;
  transform: rotate(45deg);
}

.hg-slider-gallery--arrow.arrow--next:after {
  transform-origin: 100% -10%;
  transform: rotate(-45deg);
}

/*prev arrow*/
.hg-slider-gallery--arrow.arrow--prev:before, .arrow--prev.hg-slider-gallery--arrow:after, .hg-slider-gallery--arrow.arrow--prev:after {
  display: block;
  position: absolute;
  height: 2px;
  width: 12px;
  transition: all .3s ease;
  content: '';
  top: 36px;
  left: 45px;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-slider-gallery--arrow.arrow--prev:before, .arrow--prev.hg-slider-gallery--arrow:after, .hg-slider-gallery--arrow.arrow--prev:after {
    top: 32px;
  }
}

.hg-slider-gallery--arrow.arrow--prev {
  right: 92%;
  left: 5px;
  transform: translate3d(-40%, -50%, 0);
}

.hg-slider-gallery--arrow.arrow--prev:before, .arrow--prev.hg-slider-gallery--arrow:after {
  transform-origin: 0 110%;
  transform: rotate(-45deg);
}

.hg-slider-gallery--arrow.arrow--prev:after {
  transform-origin: 0 -10%;
  transform: rotate(45deg);
}

.hg-reviews {
  display: flex;
  min-height: 530px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  align-items: center;
  /* <= 480px */
  /*ipad only*/
  /*testimonial starts*/
}

@media (max-width: 479px) {
  .hg-reviews {
    min-height: 520px;
  }
}

@media (min-width: 760px) and (max-width: 1200px) {
  .hg-reviews {
    min-height: 370px;
  }
}

@media (min-width: 480px) and (max-width: 760px) {
  .hg-reviews {
    min-height: 550px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .hg-reviews {
    min-height: 400px;
  }
}

.hg-reviews--custom-height {
  align-self: center;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  width: 50%;
  /* <= 480px */
  /* >= 768px */
}

@media (max-width: 479px) {
  .hg-reviews--custom-height {
    width: 100%;
  }
}

.hg-testimonialSlider--image-boxes {
  display: block;
  margin: 0 auto;
  height: 30px;
  width: 200px;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-testimonialSlider--image-boxes {
    width: 100px;
  }
}

.hg-testimonialSlider--image-boxes__element {
  margin: 0 auto;
}

.hg-testimonialSlider .hg-title-block__title {
  color: #ffffff;
  font-size: 18px;
}

.hg-testimonialSlider .hg-title-block__subtitle {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.73);
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-testimonialSlider .hg-title-block__subtitle {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
}

.hg-reviews-bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 900px;
  display: block;
  background-size: cover;
  background-position: center center;
  /* <= 768px */
  /*ipad only*/
}

@media (max-width: 767px) {
  .hg-reviews-bg {
    height: 1455px;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

@media (min-width: 760px) and (max-width: 1200px) {
  .hg-reviews-bg {
    background-position: 90% 70%;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .hg-reviews-bg {
    top: -200%;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 590px;
  }
}

.hg-reviews-bg span {
  display: block;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

/*rewards section*/
.hg-rewards {
  display: flex;
  position: relative;
  padding: 100px 0px;
}

.hg-rewards .hg-bgsource {
  /* <= 992px */
}

@media (max-width: 991px) {
  .hg-rewards .hg-bgsource {
    padding: 0px 0px;
  }
}

.hg-rewards__bg {
  display: block;
  background: url(../../images/texture.jpg);
  background-repeat: repeat;
  background-position: center center;
  height: 100%;
  position: relative;
  background-size: auto;
  background-attachment: fixed;
}

.hg-rewards--rewards {
  min-height: 120px;
  align-self: center;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  z-index: 2;
  padding: 20px 20px;
}

.hg-rewards--rewards h3 {
  font-size: 30px;
}

.hg-rewards--rewards h4 {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 25px;
}

.hg-testimonialSlider--arrow {
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  top: 46%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hg-testimonialSlider--arrow polyline {
  transition: all .15s ease-out;
  stroke: white;
}

.hg-testimonialSlider--arrow polyline:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.hg-testimonialSlider--arrow:hover polyline {
  stroke-width: 35;
}

/*** Totop button ***/
.totop {
  height: 49px;
  opacity: 0;
  position: fixed;
  right: -60px;
  width: 49px;
  z-index: 999;
  display: block;
  top: 85%;
  background-repeat: no-repeat;
  background-position: center 15px;
  background-color: #fff;
  font-size: 8px;
  font-weight: 400;
  color: #999;
  text-align: center;
  line-height: 1;
  border-radius: 50%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 25px 0 21px 0;
  transition: all 0.2s ease-out;
}

.totop-vissible {
  right: 10px;
  opacity: 1;
}

.totop:before {
  position: absolute;
  content: "\e080";
  top: 14px;
  margin-left: -3px;
  font-size: 6px !important;
  left: 50%;
  font-size: 11px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  transform: rotate(-90deg);
  opacity: 1;
}

.totop:hover {
  opacity: 1;
}

.totop:focus {
  opacity: 1;
}

.hg-video-section {
  padding: 60px 0px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #f7f7f8 100%);
}

.hg-video-section .hg-title-block__title {
  font-size: 44px;
  line-height: 48px;
  text-transform: none;
}

.hg-video-section .hg-title-block__subtitle {
  font-size: 18px;
}

.hg-video-section .hg-title-block h4 a {
  color: #5AD2D4;
}

.hg__playVideo:visited {
  outline: none;
}

.hg__playVideo:focus {
  outline: none;
}

.hg-video-container--content {
  padding: 30px 128px 30px 0px;
  /* <= 992px */
}

.hg-video-container--content p {
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .hg-video-container--content {
    padding-right: 0px;
  }
}

.hg-video-container__but {
  /* <= 992px */
}

@media (max-width: 991px) {
  .hg-video-container__but {
    text-align: left;
    padding-left: 0px;
  }
}

.hg-video-container--media-container {
  display: flex;
  height: 500px;
  margin: 0 auto;
  position: relative;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-video-container--media-container {
    height: 300px;
  }
}

.hg-video-container__bg {
  display: block;
  background-image: url("../../images/vir6.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
  width: 100%;
  height: 100%;
}

.hg-video-container__iconbox-video {
  align-self: center;
  margin: 0 auto;
  /* <= 992px */
  /* <= 480px */
}

@media (max-width: 991px) {
  .hg-video-container__iconbox-video {
    width: 100%;
  }
  .hg-video-container__iconbox-video .hg-column-video {
    width: calc(100% - 105px);
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 479px) {
  .hg-video-container__iconbox-video {
    width: 100%;
  }
  .hg-video-container__iconbox-video .hg-column-video {
    width: calc(100% - 102px);
    margin: 0 auto;
    padding: 0;
  }
}

.hg-about-container--content {
  padding: 0px 128px 30px 0px;
  /* <= 992px */
}

.hg-about-container--content p {
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .hg-about-container--content {
    padding-right: 0px;
  }
}

.hg-about-container__but {
  /* <= 992px */
}

@media (max-width: 991px) {
  .hg-about-container__but {
    text-align: left;
    padding-left: 0px;
  }
}

.hg-about-container--media-container {
  display: flex;
  height: 500px;
  margin: 0 auto;
  position: relative;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-about-container--media-container {
    height: 300px;
  }
}

.hg-about-container__bg {
  display: block;
  background-image: url("../../images/vir6.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
  width: 100%;
  height: 100%;
}

.hg-about-container__iconbox-video {
  align-self: center;
  margin: 0 auto;
  /* <= 992px */
  /* <= 480px */
}

@media (max-width: 991px) {
  .hg-about-container__iconbox-video {
    width: 100%;
  }
  .hg-about-container__iconbox-video .hg-column-video {
    width: calc(100% - 105px);
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 479px) {
  .hg-about-container__iconbox-video {
    width: 100%;
  }
  .hg-about-container__iconbox-video .hg-column-video {
    width: calc(100% - 102px);
    margin: 0 auto;
    padding: 0;
  }
}

.hg-vcontainer {
  width: 100%;
  text-align: left;
}

.hg-bgsource {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hg-video-container__iconbox-video {
  z-index: 1;
  text-align: center;
}

.hg-video-container__iconbox-video .hg__playVideo {
  display: inline-block;
  width: 96px;
  height: 96px;
}

#map {
  height: 600px;
}

.hg-contact {
  background-color: #f5f5f5;
  padding: 100px 0px;
}

.hg-contact .hg-title-block {
  padding-bottom: 60px;
}

.hg-contact .hg-title-block__title {
  font-size: 30px;
}

.hg-contact .hg-form-component {
  width: 100%;
}

.hg-contact .hg-form-component label {
  left: 30px;
  top: 2px;
  z-index: 99;
}

.hg-contact .hg-form-component input[type=text], .hg-contact .hg-form-component input[type=email] {
  width: 100%;
}

.hg-contact .hg-form-component .btn-gradient {
  padding: 0px 55px;
  width: auto;
  /* >= 992px */
}

@media (min-width: 992px) {
  .hg-contact .hg-form-component .btn-gradient {
    margin-left: 70px;
  }
}

.js-disable-action {
  pointer-events: none;
}

.js-cf-message {
  display: none;
  border-left: solid 3px transparent;
  padding-left: 5px;
}

.js-response-success {
  border-left-color: #9bf442;
}

.js-response-error {
  border-left-color: #dd0000;
}

/* grid room number of columns */
/* 4 columns */
.rooms.hg-catalog-4-column .rooms__item {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 50px;
  /* <= 480px */
  /* 480px - 991px */
}

@media (max-width: 479px) {
  .rooms.hg-catalog-4-column .rooms__item {
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .rooms.hg-catalog-4-column .rooms__item {
    width: 50%;
  }
}

/* 3 columns */
.rooms.hg-catalog-3-column .rooms__item {
  width: 33.3%;
  margin: 0 auto;
  margin-bottom: 50px;
  /* <= 480px */
  /* 480px - 991px */
}

@media (max-width: 479px) {
  .rooms.hg-catalog-3-column .rooms__item {
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .rooms.hg-catalog-3-column .rooms__item {
    width: 50%;
  }
}

/* 5 columns */
.rooms.hg-catalog-5-column .rooms__item {
  width: 20%;
  margin: 0 auto;
  margin-bottom: 50px;
  /* <= 480px */
  /* 480px - 991px */
}

@media (max-width: 479px) {
  .rooms.hg-catalog-5-column .rooms__item {
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 991px) {
  .rooms.hg-catalog-5-column .rooms__item {
    width: 50%;
  }
}

.rooms {
  margin-right: 20px;
  margin-left: -20px;
  overflow: hidden;
}

.rooms__item {
  float: left;
  padding: 0 15px;
}

.rooms__item .summary {
  overflow: hidden;
}

.rooms__item .summary .price {
  transition: all .3s;
}

.rooms__item .summary:hover {
  overflow: hidden;
  cursor: pointer;
}

.rooms__item .summary:hover .media a:before {
  border-bottom: 500px solid #8973F4;
  border-left: 700px solid #8973F4;
}

.rooms__item .summary:hover .price {
  transform: translate(-15px, -15px);
}

.rooms__item .media {
  position: relative;
}

.rooms__item .media a:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 70px solid #8973F4;
  border-left: 70px solid #8973F4;
  border-bottom-style: solid;
  bottom: 0;
  right: 0;
  opacity: .7;
  filter: alpha(opacity=70);
  transition: all .3s;
}

.rooms__item .media img {
  width: 100%;
  height: auto;
}

.rooms__item .title h4 {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 30px;
  margin: 16px 0 0;
}

.rooms__item .title h4 a {
  color: #2a2a2a;
  white-space: nowrap;
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rooms__item .price {
  position: absolute;
  right: 25px;
  bottom: 50px;
  text-align: right;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  line-height: 27px;
}

.rooms__item .price span {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #fff;
  display: block;
}

.rooms__item .price .price-title {
  display: none;
}

.rooms__item .price .price-value {
  font-weight: 700;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 4px;
}

.rooms__item .price .price-unit {
  font-size: 9px;
}

/* pagination */
.easyPaginateNav {
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}

.easyPaginateNav a {
  padding: 8px 13px;
  color: #5a5a5a;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 13px;
  margin: 0px 10px;
  background: none;
}

.easyPaginateNav a:hover {
  color: #8973F4;
}

.easyPaginateNav a:visited {
  text-decoration: none;
}

.easyPaginateNav .page:hover {
  background-color: #efefef;
  border-radius: 3px;
}

.easyPaginateNav .page:visited, .easyPaginateNav .page:focus {
  text-decoration: none;
}

.easyPaginateNav .page.current {
  height: 30px;
  background-color: #efefef;
  border-radius: 3px;
  font-weight: 700;
  color: #2a2a2a;
}

.easyPaginateNav .prev, .easyPaginateNav .next {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  /* <= 768px */
}

@media (max-width: 767px) {
  .easyPaginateNav .prev, .easyPaginateNav .next {
    display: none;
  }
}

.page-subheader {
  position: relative;
  margin-top: 67px;
  z-index: 0;
  width: 100%;
  height: auto;
}

@media screen and (min-width: 760px) {
  .page-subheader .container {
    padding: 0;
  }
}

.page-subheader .title {
  position: relative;
  float: left;
  padding: 5px 0px;
}

.page-subheader h4 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.page-subheader .price {
  position: relative;
  float: right;
  top: -30px;
}

.page-subheader .price .price-value {
  font-size: 18px;
}

.page-subheader .price .price-unit {
  color: #999;
  font-size: 11px;
  font-weight: 600;
}

.psub--dark {
  background-color: #535353;
}

.psub--dark .breadcrumb li {
  color: #f7f7f8;
}

.psub--dark .breadcrumb li a {
  color: #f7f7f8;
}

.psub--light {
  background-color: aliceblue;
}

.psub--light .breadcrumb li {
  color: rgba(0, 0, 0, 0.5);
}

.psub--light .breadcrumb li a {
  color: rgba(0, 0, 0, 0.8);
}

.breadcrumb {
  background: none;
  padding: 15px 0px;
}

.breadcrumb li + li:before {
  margin: 0 auto;
  padding: 8px;
  color: black;
  content: "/\00a0";
}

.breadcrumb li {
  float: left;
  position: relative;
  text-transform: uppercase;
  font-size: 11px;
  margin: 0;
}

.page-subheader-container {
  width: 90%;
  padding: 10px 0px;
  margin: 0 auto;
  height: 75px;
}

.sidebar {
  border: 1px solid #efefef;
  overflow: hidden;
  padding: 20px 15px;
  border-radius: 3px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
}

.sidebar h2 {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.sidebar .btn-sidebar {
  background: #8973F4;
  font-weight: 400;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.sidebar .btn-sidebar:hover {
  background: #2a2a2a;
  opacity: 1;
}

.sidebar .btn-gradient {
  margin-top: 20px;
}

.sidebar .btn_add_to_cart {
  width: 100%;
  margin-bottom: 20px;
  text-transform: inherit;
}

.hg-widget {
  margin-bottom: 30px;
}

.hg-widget h3 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  margin-top: 20px;
  font-size: 18px;
  color: #222;
  font-style: normal;
  font-weight: 400;
}

.hg-widget--minicart {
  text-align: center;
  margin: 20px 0 0;
}

.hg-widget h4 {
  text-align: left;
  float: left;
  font-size: 16px;
  font-weight: 500;
  width: 65%;
}

.hg-widget label {
  font-weight: 400;
  font-size: 15px;
  margin-right: 12px;
  margin-bottom: 0;
}

.hg-widget span {
  line-height: 37px;
  font-weight: 600;
  margin-left: 80px;
}

.hg-widget a {
  float: right;
  line-height: 35px;
  color: #555;
}

.hg-widget .minicart-qty span {
  color: #555;
}

.hg-widget .minicart-price span {
  color: #5AD2D4;
}

.hg-search {
  border-bottom-color: #efefef;
  padding-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 20px;
}

.search-result {
  padding-left: 0;
  margin-bottom: 20px;
}

.search-result__room {
  background: #fff;
  padding-bottom: 0;
  border: 1px solid #efefef;
  margin: 0 0 30px;
}

.search-result .media-container {
  width: 45%;
  float: left;
  /* <= 768px */
}

@media (max-width: 767px) {
  .search-result .media-container {
    width: 100%;
  }
}

.search-result .hg-room-description {
  width: 55%;
  padding-left: 30px;
  float: left;
  /* <= 768px */
}

@media (max-width: 767px) {
  .search-result .hg-room-description {
    width: 100%;
  }
}

.search-result .hg-room-description h4 {
  font-weight: 600;
}

.search-result .hg-room-description a {
  color: #2a2a2a;
}

.search-result .hg-room-description a:hover {
  color: #ffb606;
}

.hg-room-meta {
  padding-left: 0px;
  line-height: 1.5;
}

.hg-room-meta label {
  width: auto;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
}

.hg-room-meta__capacity {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #eee;
  display: inline-block !important;
}

.hg-room-meta--cap {
  margin-left: 20px;
  font-weight: 700;
  color: #5AD2D4;
  display: inline-block;
  position: relative;
  margin-bottom: 0px;
}

.hg-room-meta__max_child {
  display: inline-block !important;
}

.hg-room-meta--child {
  margin-left: 20px;
  font-weight: 700;
  color: #5AD2D4;
  display: inline-block;
  position: relative;
}

.hg-room-meta__price {
  position: relative;
  font-weight: 700;
}

.hg-room-meta--pr {
  margin-left: 20px;
}

.hg-room-meta--qt {
  display: inline-block;
  position: relative;
}

.hg-room-meta--qt select, .hg-room-meta--qt .hg-form-component-input .input-group input[type=text], .hg-form-component-input .input-group .hg-room-meta--qt input[type=text] {
  width: auto;
  border-bottom: none;
  padding: 3px 25px 3px 5px;
  height: auto;
  font-size: 100%;
}

.hg-room-meta__add_to_cart {
  margin-bottom: 0px !important;
  /* <= 768px */
}

@media (max-width: 767px) {
  .hg-room-meta__add_to_cart {
    margin-bottom: 25px !important;
  }
}

.hg-room-meta li {
  margin-bottom: 25px;
  display: block;
  padding-left: 0px;
  margin-left: 0;
}

.hg-tabs {
  clear: both;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;
}

.hg-tabs--room {
  background: white;
  z-index: 4;
  position: relative;
  padding-top: 12px;
  padding-bottom: 2px;
}

.hg-tabs li a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  color: #5a5a5a;
  position: relative;
  display: block;
  line-height: 30px;
  padding-bottom: 9px;
}

.hg-tabs li a.active:after {
  background: #5AD2D4;
}

.hg-tabs li a:after {
  content: '';
  bottom: -2px;
  height: 4px;
  background: transparent;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
}

.hg-tabs li a.active {
  color: #2a2a2a;
  text-decoration: none;
}

.hg-tabs-content {
  padding: 60px;
  background: white;
  box-shadow: 0px 15px 76px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 50px;
  /* <= 768px */
}

@media (max-width: 767px) {
  .hg-tabs-content {
    padding: 5px;
  }
}

.hg-tabs-content .tabcontent {
  display: none;
}

.hg-tabs-content .tabcontent span {
  color: #535353;
}

.hg-tabs-content .table-hover tr th, .hg-tabs-content .table-hover tr td {
  border-left: none;
  border-right: none;
  /* <= 768px */
}

@media (max-width: 767px) {
  .hg-tabs-content .table-hover tr th, .hg-tabs-content .table-hover tr td {
    padding: 5px;
  }
}

.hg-tabs-content--icon {
  color: #7f82f0 !important;
  font-size: 30px;
  line-height: 50px;
  margin-left: 11px;
  margin-right: 23px;
}

.hg-room-container {
  display: block;
  height: 470px;
  margin-bottom: 140px;
  position: relative;
  width: 100%;
  /* <= 992px */
  /* <= 480px */
}

@media (max-width: 991px) {
  .hg-room-container {
    height: 260px;
    margin-bottom: 130px;
  }
}

@media (max-width: 479px) {
  .hg-room-container {
    height: 300px;
    margin-bottom: 80px;
  }
}

@media (min-width: 996px) and (max-width: 1100px) {
  .hg-room-container {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 995px) {
  .hg-room-container {
    height: 260px;
    margin-bottom: 80px;
  }
}

.hg-room-gallery {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  right: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: auto;
}

.hg-room-gallery .cont-img {
  overflow: hidden;
  margin: 0 auto;
  height: 100%;
  margin-top: -345px;
  /* <= 480px */
}

@media (max-width: 479px) {
  .hg-room-gallery .cont-img {
    margin-top: 0px;
  }
}

@media (min-width: 480px) and (max-width: 760px) {
  .hg-room-gallery .cont-img {
    margin-top: 0px;
  }
}

.hg-room-gallery img {
  height: 100%;
  width: 100%;
}

.room-containter-image {
  height: 100%;
  min-height: 60px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.slider-nav {
  max-height: 170px;
  overflow: hidden;
  z-index: 3;
  margin-top: -50px;
  background: white;
  padding: 20px;
  box-shadow: 0px 15px 76px 0px rgba(0, 0, 0, 0.06);
  /* <= 992px */
  /* <= 480px */
  /* Add image border on active slider from nav */
}

@media (max-width: 991px) {
  .slider-nav {
    width: 80%;
  }
}

@media (min-width: 996px) and (max-width: 1100px) {
  .slider-nav {
    margin-top: -190px;
  }
}

@media (min-width: 768px) and (max-width: 995px) {
  .slider-nav {
    margin-top: -110px;
  }
}

@media (max-width: 479px) {
  .slider-nav {
    margin-top: -65px;
    max-height: 130px;
    width: 90%;
  }
}

.slider-nav .slick-slide.slick-active.is-active img {
  opacity: 1;
}

.slider-nav li {
  margin-top: 0px;
  float: left;
  /* <= 480px */
}

@media (max-width: 479px) {
  .slider-nav li {
    margin-top: 0px;
    margin: 0;
    padding: 0;
  }
}

.slider-nav li img {
  width: auto;
  height: 100%;
  opacity: 0.5;
}

.slider-nav li.slick-active.is-active:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  left: 0px;
  border: 3px solid #ffb606;
  transition: all 0.3s;
}

.slider-nav li.slick-active.is-active, .slider-nav li.slick-active.is-active:focus, .slider-nav li.slick-active.is-active:hover, .slider-nav li.slick-active.is-active:visited {
  outline: none;
}

.slider-nav .slick-track .slick-slide {
  height: 130px;
  overflow: hidden;
  /* <= 992px */
  /* <= 480px */
}

@media (max-width: 991px) {
  .slider-nav .slick-track .slick-slide {
    max-height: 91px;
  }
}

@media (max-width: 479px) {
  .slider-nav .slick-track .slick-slide {
    align-items: center;
    justify-content: center;
    display: flex;
    max-height: 104px;
  }
}

.other-rooms {
  margin-bottom: 100px;
  position: relative;
}

.other-rooms__title {
  font-size: 24px;
  position: relative;
  line-height: 40px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.other-rooms__title:after {
  content: '';
  width: 30px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffb606;
}

.other-rooms__nav {
  width: auto;
  float: right;
  margin-top: -80px;
  margin-right: 15px;
  position: relative;
}

/* remove the next slide */
.other-rooms-slider.rooms {
  margin-right: 0px;
  padding-left: 10px;
  width: 100%;
}

.other-rooms-slider.rooms li {
  padding: 0 0px;
}

.room_sample--arrow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.room_sample--arrow__next {
  margin-left: 20px;
  margin-right: 15px;
  float: right;
  background: url(../../img-assets/next.png) no-repeat center right;
}

.room_sample--arrow__prev {
  float: left;
  background: url(../../img-assets/prev.png) no-repeat center left;
}

.hg-customer {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 25px;
  border-spacing: 0;
  border-collapse: collapse;
}

.hg-customer .form-group {
  margin-bottom: 25px;
  padding-bottom: 20px;
}

.hg-customer .form-group span {
  color: red;
  vertical-align: middle;
  margin: 0 10px;
}

.hg-customer select, .hg-customer .hg-form-component-input .input-group input[type=text], .hg-form-component-input .input-group .hg-customer input[type=text] {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: auto;
}

.hg-customer input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 6px 12px;
}

.hg-customer .radio {
  padding: 20px 0px;
}

.hg-customer .radio label {
  margin-left: 0;
  font-weight: 800;
  color: #555;
  text-transform: inherit;
  font-size: 12px;
}

.hg-customer textarea {
  border: 1px solid #ccc;
}

.hg-customer label {
  margin-top: -22px;
  left: 0;
  margin-left: 50px;
}

.hg-customer td {
  border: none;
  padding-bottom: 0;
  /* <= 768px */
}

@media (max-width: 767px) {
  .hg-customer td {
    float: left;
    width: 100%;
  }
}

.hg-customer p {
  text-align: left;
  margin-bottom: 0;
  padding-bottom: 0;
}

.hg-customer .hg-tdcheck_right {
  position: relative;
  vertical-align: top;
}

.hg-customer .hg-tdcheck_right label {
  margin-left: 35px;
}

.terms_check {
  width: 100%;
  position: relative;
}

.terms_check label {
  margin-left: 0px;
  font-size: 12px;
  text-transform: inherit;
}

.terms_check label a {
  color: #555;
}

.terms_check input[type=checkbox] {
  margin-right: 20px;
}

.checkout-but .btn_add_to_cart {
  margin: 50px 0px;
}

.hg-check {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  margin: 0 0 15px;
  font-size: 40px;
  line-height: 40px;
  text-transform: inherit;
}
