.hg-iconbox{
  margin-bottom: 30px;
}
.hg-icon_section {
  padding-top: 100px;
  padding-bottom: 100px;

}
.hg-content-iconbox {
  width:90%;
  margin:0 auto;
}
.hg-icon-bottom {
  padding-top:35px;
  padding: 0 30px;
  .hg-title-block {
    margin-bottom: 55px;
    &__title {
      font-size: 30px;
    }
  }

}
.hg-iconbox--center-icon {
  margin-bottom: 30px;
    .hg-iconbox__icon-wrapper {
        margin-right:0;
        float:none;
    }
}
.hg-iconbox--small {
  .hg-iconbox__icon-wrapper {
        margin-top: 18px;
        .glyphicon {
          font-size: 18px;
        }
  @include max_mobile{
    margin-bottom:0px;
  }
  }
}
.hg-iconbox--floating {
  .glyphicon {
    font-size: 12px;
    color: #ffffff;
  }
  .hg-iconbox__icon-wrapper {
    position: relative;
    animation-duration: 2s;
    animation-name: floatingAnim;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }
}
@keyframes floatingAnim {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }
}
.hg-wcome-container {
  margin-top:65px;
  text-align:center;
  padding-bottom: 35px;
  h3{
    font-size:30px;
  }
  h4 {
    padding:10px 5px;
    line-height: 26px;
  }
}
.hg-wcome-content{
  width:65%;
  margin: 0 auto;
}
.hg-separator {
    margin: 0 auto;
    width: 10%;
    margin-bottom: 35px;
    @include max_tablet {
      width: 30%;
    }
    &--icon-no {
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: $separator;
      margin-top: 35px;
      margin-bottom: 50px;
      @include max_tablet {
        margin-bottom: 30px;
      }
    }
    &__line {
      border-top-width: 1px;
      border-top-color: #efefef;
      width: calc(50% - 27px);
      //display: inline-block;
      border-top-style: solid;
      vertical-align: middle;
      &--left {
        position:relative;
        float:left;
      }
      &--right {
        position:relative;
        display: inline-block;
        float:right;
      }
    }
    &__icon {
      display: inline-block;
      color:$greyfont;
      vertical-align: middle;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: -23px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

    }
    &__icon:before {
      font-family:'fontAwesome';
      content:'';
    }
}

.hg-iconbox__icon-wrapper.boat_wrapper {
  @media screen and (min-width:991px) and (max-width:1366px) {
    float: left;
    margin-right: 10px;

  }
}
.hg-iconbox__icon-wrapper.bed_wrapper {
  margin-right: 8px;
  @media screen and (min-width:760px) and (max-width:990px) {
    margin-bottom: 3px;
  }
  @media screen and (min-width:991px) and (max-width:1366px) {
    float: left;
    margin-right: 0px;
    margin-top: 0px;
  }
  @include max_tablet {
    margin-right: 5px;
  }
}
.hg-iconbox__icon-wrapper {
  margin-top: 10px;
  margin-bottom: 15px;
  .bed {
    @include max_tablet {
      margin-left: -20px;
    }
  }
  .boat {
    @include max_tablet {
      margin-left: -20px;
    }
  }
  @include min_mobile {
    float: left;
    margin-right: 20px;
  }
  @include tablet_landscape {
    float: none;
    margin-right: 0;
  }
  .hg-iconbox {
    &__icon {
    font-size: 60px;
    line-height: 1;
    transition: color .2s cubic-bezier(0.215,0.610,0.355,1.000);
      @include max-mobile {
        font-size: 79px;
      }
    }
  &__gli {

  }
  }
}

.hg-iconbox {
  &__content-wrapper {
    overflow: hidden;
  }
  &__title-wrapper {
      margin-top: 0;
      margin-bottom: 15px;
      line-height: 30px;
      font-style: normal;
      h3{
            font-size: 14px;
            font-weight: 700;
            a {
              color: #000;
              font-weight: 700;
            }
            a:hover {
              color: $hoverColor;
            }
      }
    }
  &__desc-wrapper {
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    p {
      color: $templateColor;
    }
  }
}
.hg-iconbox:hover .hg-iconbox__icon {
  color:$check;
}
