///////////// TOP BAR/////////////////

.site-header {
    position: absolute;
    width: 100%;
    background: transparent;
    z-index: 3;
    //responsiveness
    @include max_tablet {
      background-image: none;
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: scroll;
    }
    @include max_mobile {
      position: relative!important;
    }
    @include max_tablet {
      background: transparent;
      .site-header-col-left {
        border-right: none!important;
        padding-right: 0px;
        margin: 0 auto;
      }
      .site-logo {
        padding-top: 40px;
        padding-bottom: 20px;
        display: block;
      }
    }
    .siteheader-container {
      width: 100%;
      position: relative;
      margin: 0 auto;
      @include max_mobile {
        position: absolute;
      }
    }
    .site-header-main {
    //  height: 80px;
      @include max_tablet {
        flex-direction: column;
      }
    }
}
@include min_tablet {
  .site-header {
    background: transparent;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
  }
  .site-header.site-header--absolute.header--sticky {
    position: fixed;
  }
  .site-header.header--dark {
    background-color: #ffffff;
    border: 2px solid #f9f8f8;
    .main-menu-wrapper{
       .sh__hamburger-trigger {
         span{
          background: white;
         }
       }
     }
    .btn-fullwhite {
      background: none;
      color: white!important;
      transition: 0.1s ease-in-out;
      span{
        color: #3c3a3a;
      }
    }
    .btn-fullblack {
      background: none;
      color: black!important;
          transition: 0.1s ease-in-out;
      span{
        color: white;
      }
    }
  }

  .site-header.header--sticky {
    width: 100%;
    z-index: 9;
    will-change: transform;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
  }
 .header--is-sticked {
   /* images tranzition*/
    .site-logo-img-sticky {
      opacity: 1!important;
      transform: translateX(-50%) translateY(-50%)!important;
    }
    .site-logo-img {
      opacity: 0!important;
      transition: opacity .15s ease-in-out;
    }
  }
}
/* images when top bar sticked*/
.header--sticky {
  .site-logo-img-sticky {
      display: block;
      position: absolute;
      top: 50%;
      left: 35%;
      opacity: 0;
      transform: translateX(-50%) translateY(0);
      transition: opacity .15s ease-in-out,transform .15s ease-in-out;
      backface-visibility: hidden;
  }
  .site-logo-img-sticky+.site-logo-img {
      opacity: 1;
      transition: opacity .15s ease-in-out;
  }
}
.header {
  //not sticky header
  &--not-sticked {
    position: relative;
    .main-menu-wrapper {
      @include max_tablet {
        width: 100%;
        position: absolute;
        top: 0;
        .menu-wrapper {
            
        }
      }
    }
  }
}
///// LEFT PART OF TOP BAR - IMG LOGO///////////////////////
.site-header-col-left {
        padding-right: 35px;
}
.site-logo-img {
    max-width: 100%;
    width: 196px;
    height: 36px;
}
.site-logo-anch {
    padding: 0;
    display: table-cell;
    position: relative;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    text-transform: none;
}
.menu-wrapper {
    padding-left: 35px;
    padding-top: 23px;
}
.site-logo span {
    white-space: nowrap;
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-family: Poppins;
    text-transform: uppercase;
    letter-spacing: 2px;
    visibility: inherit;
}
.site-logo.black span {
      color: rgb(0, 0, 0)!important;
}
#logo-container {
  padding: 15px 0;
}
///// RIGHT PART OF TOP BAR///////////////////////

.main-menu-wrapper {
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  display: inline-flex;
  height: 100%;
  line-height: 65px;
  .sh-component:hover {
      text-decoration: none;
  }
  .sh-component:focus {
      text-decoration: none;
  }
  .sh-component:visited {
      text-decoration: none;
  }
  .sh-component {
    margin-left: 20px;
    margin-right: 20px;
    span {
        color: #fff;
        white-space: nowrap;
        font-size: 16px;
        line-height: 40px;
        font-weight: 500;
        font-family: Poppins;
        text-transform: uppercase;
        letter-spacing: 2px;
        visibility: inherit;
        @include max_mobile {
          font-size: 12px;
        }

    }
    .fa {
      font-size: 18px;
      margin-right:5px;
    }

    @include max_tablet {
      margin-left:0px;
    }
  }
}

///// TOGGLE OVERLAY///////////////////////

/* hamburger icon*/

.sh__hamburger-trigger {
	width: 20px;
	height: 20px;
	opacity: 1;
	margin-right: 0;
	position: relative;
	display: block;
  span {
   height: 3px;
   background: #fff;
   display: block;
   position: absolute;
   width: 100%;
   border-radius: 2px;
   opacity: 1;
   transform: rotate(0deg);
   transition: .25s ease-in-out;
  }
  .onwhite {
    background: #000;
  }
  span:first-child {
    top: 0;
  }
  span:nth-child(2) {
    top: 6px;
  }
  span:nth-child(3) {
    top: 12px;
  }
  &.is-active {
    span:nth-child(1) {
      transform: rotate(135deg);
    }
    span:nth-child(2) {
      transform: rotate(-135deg);
    }
    span:nth-child(3) {
      transform: rotate(-135deg);
    }
  }
}

/* Menu icon hover state */
.sh__hamburger-trigger:hover {
  span {background: #{$templateColor};}
 }

/***  Navigation overlay ***/

.sh__navOverlay {
	background-color: rgba(255,255,255,0.98);
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	min-height: 100%;
	width: 100%;
	transform-style: preserve-3d;
	backface-visibility: hidden;
  &.is-active {
  	transform: translateY(0) translateZ(0);
  	transition: transform .5s cubic-bezier(.165,.84,.44,1) 0ms,-webkit-transform .5s cubic-bezier(.165,.84,.44,1) 0ms;
  }
  &--closed {
  	transform: translateY(-100%) translateZ(0);
  	transition: transform .5s cubic-bezier(.895,.03,.685,.22) 0ms,-webkit-transform .5s cubic-bezier(.895,.03,.685,.22) 0ms;
  }

}

/* close button with animations */
.button-navOverlay-close {
	position: fixed;
	z-index: 2;
	height: 54px;
	width: 54px;
	border-radius: 50%;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	transition: opacity .2s ease-out,-webkit-transform .7s ease-out;
	transition: transform .7s ease-out,opacity .2s ease-out;
	transition: transform .7s ease-out,opacity .2s ease-out,-webkit-transform .7s ease-out;
	opacity: .8;
	right: 30px;
	top: 30px;
  span {
  	position: relative;
  	display: block;
  	z-index: 1;
  	width: 100%;
  	height: 100%;
  	text-indent: 100%;
  	white-space: nowrap;
  }
  span:after {
    @extend.content-span;

  }
  span:before {
    @extend.content-span;

  }
}
.content-span {
  content: "";
	width: 2px;
	height: 0;
	display: block;
	top: 50%;
	left: 50%;
	position: absolute;
	background: #000;
	transition: height .3s ease-out;
}

.button-navOverlay-close {
  span:before {
  	transform: translate(-50%,-50%) rotate(45deg);
  	transition-delay: .6s;
  }
  span:after {
  	transform: translate(-50%,-50%) rotate(-45deg);
  	transition-delay: .8s;
  }
}
.content-span-close {
  height: 15px;
}

.sh__navOverlay {
  &.is-active {
    //close button rotate
    .button-navOverlay-close {
      span:before {
          @extend.content-span-close;
      }
      span:after {
          @extend.content-span-close;
      }
      transform: rotate(180deg);
      //close button circle
      & circle {
        stroke-dashoffset: 0;
      	stroke-opacity: 1;
      }
    }
  }
}

.button {
  &-navOverlay-close svg {
  	position: absolute;
  	top: 0;
  	left: 0;
  }
  &-navOverlay-close circle {
  	transition: stroke-dashoffset .6s ease-out .4s,stroke-width .2s ease-out,stroke-opacity .2s ease-out;
  	stroke: #000;
  	stroke-width: 1;
  }
  /* circle hover*/
  &-navOverlay-close:hover circle {
  		stroke-width: 2;
  }
}


/* navigation overlay menu with animations */
.sh__navOverlay-inner {
	display: block;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: 60px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	overflow-x: hidden;
}
.sh__navOverlay {
  &--menuWrapper {
  	width: 100%;
  	display: flex;
  	align-items: center;
  	flex: 1 1 100%;
  	min-height: calc(100% - 80px);
  }
  &--menuWrapper-inner {
    width: 100%;
    margin-top:20px;
    padding-top: 60px;
    @include max_tablet {
      padding-top: 0px;
    }
    @include min_desktop {
      min-height: auto;
      padding: 20px 0px 40px 0;
    }
  }
  &--mainNav {
  	letter-spacing: 1px;
  	text-align: center;
  	display: block;
  	position: relative;
  	padding: 40px 0;
  	width: 100%;
    //li when active
    li {
        opacity: 1;
        transform: translateY(0);
        transition: transform .5s ease 0s,opacity .4s ease 0s,-webkit-transform .5s ease 0s;
        position: relative;
        backface-visibility: hidden;

    }
  }
}

//ul li a with animations
.ovr-menu {
  @include e(item) {
        display: block;
        text-align:center;
        font-family: $titleFont;
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 1em;

        a {
          padding: .17em 0;
          font-size: 2em;
          transition: .2s ease-out;
          transition-delay: 0s;
          color: hsla(0,0%,7%,.85);
          font-family: $titleFont;
        }
        a:hover {
          color: $hoverColor;
        }
  }

  @include e(item):first-child {
    transition-delay: .06s;
    //first child color
    a {
      color: $hoverColor;
    }
  }
  @include e(item):nth-child(2) {
    transition-delay: .12s;
  }
  @include e(item):nth-child(3) {
    transition-delay: .18s;
  }
  @include e(item):nth-child(4) {
    transition-delay: .24s;
  }
  @include e(item):nth-child(5) {
    transition-delay: .3s;
  }
  @include e(item):nth-child(6) {
    transition-delay: .36s;
  }

}



//li when closed
.sh__navOverlay {
    &--closed {
      .sh {
        &__navOverlay {
          &--mainNav {
            li {
              opacity: 0;
            	position: relative;
            	backface-visibility: hidden;
            	transform: translateY(-100%);
            	transition: transform .25s ease-in 0s,opacity .5s ease 0s,-webkit-transform .25s ease-in 0s;
            }
          }
        }
    }
  }

}


///// COPYRIGHT/////////
.copyText-wrapper {
    flex: 1 1 auto;
    margin-left: 60px;
    height: 80px;
      @include max_tablet {
        text-align: center;
        margin-left:0px;
      }
      .copyText {
          color: black;
          font-size: 1em;
          letter-spacing: 2px;
      }
}
