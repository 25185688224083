#rev_slider_1_1 {
  .zeus.tparrows {
  	cursor:pointer;
  	min-width:70px;
  	min-height:70px;
  	position:absolute;
    display:block;
  	z-index:100;
  	border-radius:50%;
  	overflow:hidden;
  	background:rgba(0,0,0,0.1);
    //FONT ICON ARROW
    .tp-leftarrow:before {
  		content:"\e824";
  	}
    .tp-rightarrow:before {
  		content:"\e825";
    }
	}
  .zeus.tparrows:before {
    font-family:"revicons";
    font-size:20px;
    color:rgb(255,255,255);
    display:block;
    line-height:70px;
    text-align:center;
    z-index:2;
    position:relative;
  }
  .tp-title-wrap {
		background:rgba(0,0,0,0.5);
		width:100%;
		height:100%;
		top:0px;
		left:0px;
		position:absolute;
		opacity:0;
		transform:scale(0);
		transition:all 0.3s;
		border-radius:50%;
	}
  .tp-arr-imgholder {
     width:100%;
     height:100%;
     position:absolute;
     top:0px;
     left:0px;
     background-position:center center;
     background-size:cover;
     border-radius:50%;
     transform:translatex(-100%);
     transition:all 0.3s;
   }
}
//HOVER IMAGES THUMBS ANIMATIONS
#rev_slider_1_1 {
  .zeus.tp-rightarrow .tp-arr-imgholder {
    transform:translatex(100%);
   }
  .zeus.tparrows:hover {
    .tp-arr-imgholder {
     transform:translatex(0);
     opacity:1;
     }
   .tp-title-wrap {
    transform:scale(1);
    opacity:1;
     }
   }
 }
 .hg-slideshow {
     position: relative;
     top: 0;
     z-index: 0;
     width: 100%;
 }
