/* grid room number of columns */
/* 4 columns */
.rooms.hg-catalog-4-column {
    .rooms__item {
      width:50%;
      margin:0 auto;
      margin-bottom: 50px;
      @include max_mobile {
        width: 100%;
      }
      @include mobile_tablet {
        width: 50%;
      }
    }
}
/* 3 columns */
.rooms.hg-catalog-3-column {
    .rooms__item {
      width:33.3%;
      margin:0 auto;
      margin-bottom: 50px;
      @include max_mobile {
        width: 100%;
      }
      @include mobile_tablet {
        width: 50%;
      }
    }
}
/* 5 columns */
.rooms.hg-catalog-5-column {
    .rooms__item {
      width:20%;
      margin:0 auto;
      margin-bottom: 50px;
      @include max_mobile {
        width: 100%;
      }
      @include mobile_tablet {
        width: 50%;
      }
    }
}
.rooms {
  margin-right: 20px;
  margin-left: -20px;
  overflow: hidden;
  &__item {
    float: left;
    padding: 0 15px;

    .summary {
      overflow: hidden;
      .price {
        transition: all .3s;
      }
    }
    .summary:hover {
      overflow: hidden;
      cursor:pointer;
      .media {
        a:before {
          border-bottom: 500px solid $catalogue;
          border-left: 700px solid $catalogue;
        }
      }
      .price {
        transform: translate(-15px,-15px);
      }
    }
    .media {
      position: relative;
      a:before {
         content: '';
         position: absolute;
         width: 0;
         height: 0;
         border-bottom: 70px solid $catalogue;
         border-left: 70px solid $catalogue;
         border-bottom-style: solid;
         bottom: 0;
         right: 0;
         opacity: .7;
         filter: alpha(opacity=70);
         transition: all .3s;
      }
      img {
        width:100%;
        height: auto;
      }
    }
    .title {
      h4 {
        font-family:$defaultFont;
        font-size: 14px;
        line-height: 30px;
        margin: 16px 0 0;
        a {
          color: $cataloguetitle;
          white-space: nowrap;
          max-width: 100%;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .price {
      position: absolute;
      right: 25px;
      bottom: 50px;
      text-align: right;
      font-weight: 700;
      font-size: 24px;
      color: #fff;
      line-height: 27px;
      span {
        font-family: $btnFont;
        font-size: 16px;
        color: #fff;
        display:block;
      }
      .price-title {
        display:none;
      }
      .price-value {
        font-weight: 700;
        border-bottom: 1px solid rgba(255,255,255,.3);
        padding-bottom: 4px;
      }
      .price-unit {
        font-size: 9px;
      }
    }
  }
}
/* pagination */
.easyPaginateNav {
  display:block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;

  a {
    padding: 8px 13px;
    color: #5a5a5a;
    font-family: $btnFont;
    font-size: 13px;
    margin: 0px 10px;
    background:none;
  }
  a:hover {
    color:$catalogue;

  }
  a:visited {
    text-decoration: none;

  }
  .page:hover {
    background-color:$separator;
    border-radius:3px;
  }
  .page:visited,.page:focus {
    text-decoration: none;
  }

  .page.current {
    height:30px;
    background-color:$separator;
    border-radius:3px;
    font-weight: 700;
    color: $cataloguetitle;
  }
  .prev,.next {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.5px;
    @include max_tablet {
      display:none;
    }
  }
}
