.hg-promotions {
  padding-bottom: 120px;
  @include max_desktop {
    padding-bottom:60px;
  }
}
.hg-promotions-container {
  @extend .hg-wcome-container;
  h3{
    font-size:30px;
  }
  h4 {
    padding:10px 5px;
    line-height: 26px;
  }

}
.hg-promotions-content {
  @extend .hg-wcome-content;
}
.hg-prom-col {
  padding:0px 25px;
}
.hg-prom-content {
  margin-bottom:25px;
  &--imgbox {
    height: 321px;
    margin-bottom: 30px;
    .image-boxes-img {
      transition: opacity .2s ease-out;
    -webkit-backface-visibility: hidden;
    }
    .image-boxes-img:hover {
      opacity:0.75;
    }
  }
  &__wrapper {
    height:100%;

    .imglink {
      height:100%;
      .image-boxes-img {
          height: 100%;
      }
    }
    .imglink:hover {
        transition: opacity .2s ease-out;
    }
  }
  &--txt {
    h3 {
      font-size: 18px;
      text-transform: none;
    }
    h4 {
      font-family:$defaultFont;
      font-size: 14px;

    }
  }
}
.image-boxes-img {
    width: 100%;
    height: auto;
    .cover-fit-img {
      height: 100%;
    }
}
.cover-fit-img {
    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
}
