footer {
  border-top: 1px solid $separator;
}
.hg-footer {
  padding: 100px 0px;
  //border-top: 1px solid $separator;
  &--row {
    text-align:center;
  }
  .hg-title-block {
    margin:0 auto;
    &__title {
      font-size: 22px;
      font-weight: 700;
      font-style: normal;
      text-transform: none;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
.footer-nav {
  margin: 0 0 15px 0;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  float: left;
  &__item{
    @include max_tablet {
      display: block;
      margin: 0 10px;
      position: relative;
    }
    a{
      font-size: 14px;
      line-height: 26px;
      color: #000;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -ms-transition: all 0.3s;
      transition: all 0.3s;
    }
    a:hover {
      color: $hoverColor;
    }
  }
  &__item:first-child{
    a {
      font-weight: 600;
    }
  }

}
.footer-social {
  padding: 0;
  margin-top: 10px;
  left: -15px;
  top: -20px;
  position: relative;
  &__item {
    margin: 5px 3px;
    a {
          padding: 22px;
          text-align:center;
          display: block;
    }
    .fa {
      color: #000;
      font-size: 18px;
    }
  }
}
