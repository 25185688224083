/*** Totop button ***/
.totop {
	height: 49px;
	opacity: 0;
	position: fixed;
	right: -60px;
	width: 49px;
	z-index: 999;
	display: block;
	top: 85%;
	background-repeat: no-repeat;
	background-position: center 15px;
	background-color: #fff;
	font-size: 8px;
	font-weight: 400;
	color: #999;
	text-align: center;
	line-height: 1;
	border-radius: 50%;
	box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
	padding: 25px 0 21px 0;
	transition: all 0.2s ease-out;
}
.totop-vissible {
	right: 10px;
	opacity: 1;
}
.totop:before {
	position: absolute;
	content: "\e080";
	top: 14px;
  margin-left: -3px;
  font-size: 6px!important;
	left: 50%;
	font-size: 11px;
	display: inline-block;
	font-family: "Glyphicons Halflings";
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	transform: rotate(-90deg);
	opacity: 1;
}
.totop:hover {
	opacity: 1;
}
.totop:focus {
	opacity: 1;
}
