
/*** Variables for colors fonts and screens ***/
@import '_generalSettings';

/*** Misc settings ***/
@import '_mixins';
/***@import 'others/_animations.scss';


/*** Pages ***/
@import '_pages';

/*** Elements ***/
@import 'elements/_footerMenu';
@import 'elements/_gallery';
@import 'elements/_header';
@import 'elements/_iconBox';
@import 'elements/_imageBox';
@import 'elements/_menu';
@import 'elements/_searchForm';
@import 'elements/_slider';
@import 'elements/_sliderGallery';
@import 'elements/_testimonialSlider';
@import 'elements/_topButton';
@import 'elements/_videoBox';
@import 'elements/_services';
@import 'elements/_grid-catalogue';
@import 'elements/_subheader';
@import 'elements/_sidebar';
@import 'elements/_searchresult';
@import 'elements/_tabs';
@import 'elements/_roomslider';
@import 'elements/_customer';
