.hg-reviews {
  display: flex;
  min-height: 530px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  align-items: center;
  @include max-mobile {
    min-height:520px;
  }
  @media (min-width:760px) and (max-width:1200px) {
    min-height:370px;
  }
  @media (min-width:480px) and (max-width:760px) {
    min-height:550px;
  }
  /*ipad only*/
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
        min-height:400px;
    }

  /*testimonial starts*/

  &--custom-height {
    align-self: center;
    margin: 0 auto;
    vertical-align: middle;
    text-align: center;
    width: 50%;
    @include max-mobile {
      width: 100%;

    }
    @include min_tablet {

    }

  }
}
.hg-testimonialSlider {
  &--image-boxes {
    display: block;
    margin: 0 auto;
    height: 30px;
    width: 200px;
    @include max-mobile {
      width: 100px;
    }
    &__element {
    margin: 0 auto;
    }
  }
  .hg-title-block {
    &__title{
      color: #ffffff;
      font-size: 18px;
    }
    &__subtitle {
      font-size: 14px;
      color: rgba(255,255,255,0.73);
      @include max-mobile {
        font-family: $defaultFont;
      }
    }
  }
}

.hg-reviews-bg {
  position: absolute;
  //top: 0;
  left: 0;
  width: 100%;
  height: 900px;
  display: block;
  //margin-top: -500px;
  background-size: cover;
  background-position: center center;
  @include max-tablet {
    //top:40%;
    height:1455px;
    background-position: center center;
    background-repeat: no-repeat;

  }
  @media (min-width:760px) and (max-width:1200px) {
    //top: -100%;
    background-position: 90% 70%;
    background-repeat: no-repeat;
  }
  
  /*ipad only*/
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    top: -200%;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 590px;
  }


}

.hg-reviews-bg span {
  display: block;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

/*rewards section*/
.hg-rewards {
  display: flex;
  position: relative;
  padding:100px 0px;
  .hg-bgsource {
    @include max_desktop {
        padding: 0px 0px;
    }
  }
  &__bg {
    display: block;
    background: url(../../images/texture.jpg);
    background-repeat: repeat;
    background-position: center center;
    height: 100%;
    position:relative;
    background-size: auto;
    background-attachment: fixed;

  }
  &--rewards {
    min-height: 120px;
    align-self: center;
    margin: 0 auto;
    vertical-align: middle;
    text-align: center;
    z-index: 2;
    padding:20px 20px;
    h3 {
        font-size:30px;
    }
    h4 {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 25px;
    }

  }
}
.hg-testimonialSlider {
    &--arrow {
      width: 18px;
      height: 18px;
      cursor: pointer;
      position: absolute;
      top: 46%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      polyline {
          transition: all .15s ease-out;
          stroke: white;
      }
      polyline:focus {
          outline: -webkit-focus-ring-color auto 5px;
      }
      &:hover polyline{
        stroke-width: 35;
      }
    }
}
