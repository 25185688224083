.hg-search {
    border-bottom-color: #efefef;
    padding-bottom:20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 20px;
}
.search-result {
  padding-left:0;
  margin-bottom: 20px;
  &__room {
    background: #fff;
    padding-bottom: 0;
    border: 1px solid $separator;
    margin: 0 0 30px;
  }
  .media-container {
    width:45%;
    float:left;
    @include max_tablet {
      width:100%;
    }
  }

  .hg-room-description {
    width: 55%;
    padding-left: 30px;
    float:left;
    @include max_tablet {
      width:100%;
    }
    h4 {
      font-weight: 600;
    }
    a {
      color:$cataloguetitle;
    }
    a:hover {
      color:$orange;
    }
  }
}
.hg-room-meta {
  padding-left:0px;
  line-height: 1.5;
  label {
    width:auto;
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
  }
  &__capacity {
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #eee;
    display:inline-block !important;

  }
  &--cap {
    margin-left: 20px;
    font-weight: 700;
    color:$turqoise;
    display: inline-block;
    position:relative;
    margin-bottom: 0px;
  }
  &__max_child {
    display: inline-block !important;

  }
  &--child {
    margin-left: 20px;
    font-weight: 700;
    color:$turqoise;
    display: inline-block;
    position:relative;
  }
  &__price {
    position: relative;
    font-weight: 700;

  }
  &--pr {
    margin-left: 20px;
  }
  &__quantity {

  }
  &--qt {
    display: inline-block;
    position:relative;
    select {
      width: auto;
      border-bottom: none;
      padding: 3px 25px 3px 5px;
      height: auto;
      font-size: 100%;
    }
  }
  &__add_to_cart {
    margin-bottom:0px !important;
    @include max_tablet {
      margin-bottom: 25px !important;
    }
  }
  li {
    margin-bottom: 25px;
    display: block;
    padding-left:0px;
    margin-left: 0;
  }
}
