/* layout */
$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 992px;
$large-desktop-width: 1200px;
$slarge-desktop-width: 1680px;
$glyph: 'Glyphicons Halflings';
$desktop-height: 1050px;

/* colors */
$templateColor: #535353;
$grey: #f5f5f5;
$separator: #efefef;
$titleColor: #000;
$hoverColor: #7f82f0;
$elementsColor: #181834;
$formColor: #969696;
$border: #E5E5E5;
$selectColor: #7a7a7a;
$turqoise: #5AD2D4;
$check: #5AD2D4;
$greyfont: #e2e2e2;
$titleIcon: #323232;
$lightgrey: #f7f7f8;
$catalogue: #8973F4;
$cataloguetitle: #2a2a2a;
$subheader: #fcfcfc;
$orange:#ffb606;

/* fonts */
$defaultFont: 'Open Sans',Helvetica,Arial,sans-serif;
$titleFont: 'Poppins',Helvetica,Arial,sans-serif;
$btnFont:'Montserrat',Helvetica,Arial,sans-serif;
$fontello:'fontello';
@font-face {
  font-family: 'fontello';
  src: url('../../fonts/fontello/fontello.eot');
  src: url('../../fonts/fontello/fontello'),
    url("../../fonts/fontello/fontello.woff") format("woff"),
    url("../../fonts/fontello/fontello.ttf") format("opentype"),
    url("../../fonts/fontello/fontello.svg#fontello") format("svg");
    font-weight: normal;
    font-style: normal;
}
