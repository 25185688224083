.hg-room-container {
  display: block;
  height: 470px;
  margin-bottom: 140px;
  position: relative;
  width:100%;
  @include max_desktop {
    height: 260px;
    margin-bottom: 130px;
  }
  @include max_mobile {
    height:300px;
    margin-bottom: 80px;
  }
  @media (min-width:996px)and (max-width: 1100px){
    margin-bottom: 30px;
  }
  @media (min-width:768px)and (max-width: 995px){
    height: 260px;
    margin-bottom: 80px;
  }
}
.hg-room-gallery {
  position:absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  right: 0;
  top: 0;
  z-index: 2;
  width:100%;
  height: auto;
  .cont-img {
    overflow: hidden;
    margin: 0 auto;
    height:100%;
    margin-top: -345px;
    @include max_mobile {
        margin-top: 0px;
    }
    @media (min-width:480px)and (max-width: 760px){
      margin-top: 0px;
    }

  }
  img {
    height:100%;
    width:100%;
  }
}

.room-containter-image {
  height: 100%;
  min-height: 60px;
  position: relative;
  width: 100%;
  z-index: 1;
}
.slider-nav {
  max-height: 170px;
  overflow: hidden;
  z-index:3;
  margin-top: -50px;
  background: white;
  padding: 20px;
  box-shadow: 0px 15px 76px 0px rgba(0,0,0,0.06);
  @include max_desktop {
    width: 80%;
  }
  @media (min-width:996px)and (max-width: 1100px){
    margin-top: -190px;
  }
  @media (min-width:768px)and (max-width: 995px){
    margin-top: -110px;
  }
  @include max_mobile {
    margin-top: -65px;
    max-height: 130px;
    width:90%;
  }
  .slick-slide.slick-active.is-active {
    img {
      opacity: 1;

    }
  }
  li {
    margin-top: 0px;
    float:left;
    @include max_mobile {
      margin-top: 0px;
      margin:0;
      padding:0;
    }
    img {
      width:auto;
      height:100%;
      opacity: 0.5;
    }
  }
  /* Add image border on active slider from nav */
  li.slick-active.is-active:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    left: 0px;
    border: 3px solid #ffb606;
    transition: all 0.3s;
  }

  li.slick-active.is-active, li.slick-active.is-active:focus,li.slick-active.is-active:hover, li.slick-active.is-active:visited {
    outline:none;
  }
  .slick-track {
      .slick-slide{
        height: 130px;
        overflow:hidden;
        @include max_desktop {
          max-height: 91px;
        }
        @include max_mobile {
          align-items: center;
          justify-content: center;
          display:flex;
          max-height: 104px;
        }

    }
  }
}
.other-rooms {
  margin-bottom: 100px;
  position:relative;
  &__title {
    font-size: 24px;
    position: relative;
    line-height: 40px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  &__title:after {
    content: '';
    width: 30px;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffb606;
  }
  &__nav {
    width: auto;
    float: right;
    margin-top: -80px;
    margin-right: 15px;
    position:relative;
  }
}
/* remove the next slide */
.other-rooms-slider.rooms {
      margin-right: 0px;
      padding-left: 10px;
      width: 100%;
      li {
        padding: 0 0px;
      }
}
.room_sample--arrow {
  width: 24px;
  height: 24px;
  cursor: pointer;

  &__next {
    margin-left: 20px;
    margin-right: 15px;
    float: right;
    background: url(../../img-assets/next.png) no-repeat center right;

  }
  &__prev {

    float: left;
    background: url(../../img-assets/prev.png) no-repeat center left;

  }
}
