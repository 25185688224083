.hg-insta {
	padding: 70px 0px;
	background-color: $lightgrey;
	&--row {
		width:100%;
		margin:0 auto;
		text-align:center;
		.img-box {
			    margin-bottom: 30px;
		}
		p {
			font-family:$titleFont;
			font-size: 18px;
			color: #333333;
		}
	}
}
/*gallery*/
.instashow-gallery-media-info {
		display: block;
    position: absolute;
    visibility: hidden;
    z-index: 3;
    width: 80%;
    max-height: 80%;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%) translateY(-40%);
    text-align: center;
		overflow: hidden;
    transition: all .3s ease;
  	font-size: 14px;

		span {
			color: #fff;
		}
}
.hg-slider-media-cover {
	display: block;
	position: absolute;
	visibility: hidden;
	z-index: 2;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	transition: all .4s ease;
	background: rgba(126,130,240,0.7);
}
.hg-slider-jstrigger {
	height: 300px;
	overflow: hidden;
	@include max_mobile {
		height: 63px;
	}
	@media (max-width:750px)and(min-width:491px){
		 height: 99px;
	}
	@media (max-width:991px)and(min-width:750px) {
		height: 150px;
	}
	@media (max-width:1500px)and(min-width:992px) {
		height: 200px;
	}
}
.hg-slider {
	&__item {
		width:20%;
		position: relative;
		float:left;
		height:100%;
	}
	&--image_link {
    &:hover {
      .hg-icon {
        display: block;
        opacity: 1;
        margin-top: 0;
      }
      img {
        transform: scale(1.1);
        transition-duration: 2s;
				transition: transform 0.7s linear;
      }
			.hg-slider-media-cover {
				visibility: visible;
				opacity: 1;
			}
			.instashow-gallery-media-info {
				visibility: visible;
				opacity: 1;

			}
    }
  }
}

.hg-slider__img-container {
    overflow: hidden;
    margin: 0 auto;
    position: absolute;
    top: 0px;
    left: 0px;
    img {
			width:100%;
			left: 50%;
      height: 100%;
      transition: transform 0.7s linear;
    }
}
/*gallery arrows*/
.hg-slider-gallery {
	direction: ltr!important;
    &--arrow {
			z-index: 10;
	    width: 74px;
	    height: 74px;
      cursor: pointer;
      position: absolute;
      top: 46%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
			background: rgba(255,255,255,.9);
			border-radius: 50%;
			@include max_mobile {
				width: 63px;
		    height: 63px;
				top: 49%;
				right:5px;
			}
		}
		&--arrow:hover {
			background: #fff;
    }
		&--arrow:before {
			background: #5AD2D4!important;
    }
		&--arrow:after {
			@extend .hg-slider-gallery--arrow:before;
    }
}

/*next arrow*/
.hg-slider-gallery--arrow.arrow--next:before, .hg-slider-gallery--arrow.arrow--next:after {
	display: block;
  position: absolute;
  height: 2px;
  width: 12px;
  transition: all .3s ease;
  content: '';
	top: 36px;
  left: 13px;
	@include max_mobile {
		top: 32px;
		left:6px;
	}
}
.hg-slider-gallery--arrow.arrow--next {

	right: 0;
	left: auto;
  transform: translate3d(40%,-50%,0);


}
.hg-slider-gallery--arrow.arrow--next:before {
	transform-origin: 100% 110%;
  transform: rotate(45deg);
}
.hg-slider-gallery--arrow.arrow--next:after {
	transform-origin: 100% -10%;
  transform: rotate(-45deg);
}
/*prev arrow*/
.hg-slider-gallery--arrow.arrow--prev:before, .hg-slider-gallery--arrow.arrow--prev:after {
	display: block;
  position: absolute;
  height: 2px;
  width: 12px;
  transition: all .3s ease;
  content: '';
	top: 36px;
	left: 45px;
	@include max_mobile {
		top: 32px;
	}
}
.hg-slider-gallery--arrow.arrow--prev {
	right: 92%;
	left: 5px;
  transform: translate3d(-40%,-50%,0);
}
.hg-slider-gallery--arrow.arrow--prev:before {
	transform-origin: 0 110%;
	transform: rotate(-45deg);
}
.hg-slider-gallery--arrow.arrow--prev:after {
	transform-origin: 0 -10%;
	transform: rotate(45deg);
}
