/* Homepage */
body {
    font-size: 13px;
    font-family: $defaultFont;
		line-height: 1.7;
    color: $templateColor;

    #page_wrapper {
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: scroll;
      }
}
.pt-0 {
  padding-top: 0!important;
}
.p-0 {
  padding: 0!important;
}
.m-130 {
  margin: 130px 0;
}
.m-200 {
  margin: 200px 0;
}
.m-300 {
  margin: 300px 0;
}
.breadcrumb {
  margin: 10px 0;
}
footer .footer__last {
  background: aliceblue;
  padding: 10px 0
}
.book {
  padding: 100px 50px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  margin: 20px
}
.form-group {
  padding-bottom: 40px;
}
#returnmessage {
  font-size:14px;
  color:green;
  text-align:center;
}
.book .column h2 {
  font-weight: 700;
  margin: 20px 0;
}
.clouding {
  position: absolute;
  top: 280px;
  z-index: 3
}
.cloud {
  width: 600px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: -300px;
  left: -100px;
}

#cloud-base {
  top: 90px;
  height: 100px;
  filter: url(#filter-base);
  box-shadow: 200px 170px 19px 40px rgba(255, 255, 255, 0.9);
}
#cloud-back {
  top: 90px;
  height: 30px;
  width: 500px;
  filter: url(#filter-back);
  box-shadow: 200px 200px 10px 40px rgba(215, 215, 215, 0.3);
}

#cloud-mid {
  top: 80px;
  width: 580px;
  height: 35px;
  filter: url(#filter-mid);
  box-shadow: 210px 250px 28px 30px rgba(66, 105, 146, 0.2);
}

#cloud-front {
  top: 70px;
  width: 450px;
  height: 40px;
  left: -20px;
  filter: url(#filter-front);
  box-shadow: 210px 272px 30px 0px rgba(0, 0, 0, 0.4);
}
.sun { 
  position: absolute;
  top:340px;
  left:250px;
  right:0;
  bottom:0;
  margin: auto;  
  width:70px;
  height:70px;
  border-radius:50%;  
  background:#fff;
  opacity:0.9;      
  box-shadow: 0px 0px 40px 15px white;
  z-index: 4  
}

.ray_box {
  position: absolute;
  margin: auto;
  top:0px;
  left:0;
  right:0;
  bottom:0; 
  width:70px;  
  -webkit-animation: ray_anim 120s linear infinite;
  animation: ray_anim 120s linear infinite;
}
.ray {  
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%);
    background: linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%); 
    margin-left:10px;
    border-radius:80% 80% 0 0;
    position:absolute;
    opacity:0.1;
}

.ray1 {    
    height:170px;
    width:30px;
   -webkit-transform: rotate(180deg);
    top:-175px;
    left: 15px;
}
.ray2 {
    height:100px;
    width:8px;
   -webkit-transform: rotate(220deg);
    top:-90px;
    left: 75px;
}
.ray3 {
    height:170px;
    width:50px;
   -webkit-transform: rotate(250deg);
    top:-80px;
    left: 100px;
}
.ray4 {
    height:120px;
    width:14px;
   -webkit-transform: rotate(305deg);
    top:30px;
    left: 100px;
}
.ray5 {
    height:140px;
    width:30px;
   -webkit-transform: rotate(-15deg);
    top:60px;
    left: 40px;
}
.ray6 {
    height:90px;
    width:50px;
   -webkit-transform: rotate(30deg);
    top:60px;
    left: -40px;
}
.ray7 {
    height:180px;
    width:10px;
   -webkit-transform: rotate(70deg);
    top:-35px;
    left: -40px;
}
.ray8 {
    height:120px;
    width:30px;
   -webkit-transform: rotate(100deg);
    top:-45px;
    left:-90px;
}
.ray9 {
    height:80px;
    width:10px;
   -webkit-transform: rotate(120deg);
    top:-65px;
    left:-60px;
}
.ray10 {
    height:190px;
    width:23px;
   -webkit-transform: rotate(150deg);
    top:-185px;
    left: -60px;
}


@-webkit-keyframes ray_anim { 
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg);}    
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}

.btn.btn-rounded.btn-medium {
    padding: 12px 32px;
}
.btn.btn-rounded {
    border-radius: 50px;
}
.btn.btn-transparent-dark-gray {
    background: transparent;
    border-color: #232323;
    color: #232323;
}
.btn.btn-medium {
    font-size: 12px;
    padding: 6px 25px 5px;
}
.btn {
    display: inline-block;
    border: 2px solid transparent;
    color: inherit;
    letter-spacing: .5px;
    line-height: inherit;
    border-radius: 0;
    text-transform: uppercase;
    width: auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    white-space: nowrap;
}

.weatherwidget-io {
  position: absolute;
  width: 30%;
  top: -40px;
  z-index: 4;
  left: -10px
}
.hg-textbox {
  border-top: 1px solid #ddd;
}

@media (max-width: 991px) {
  .weatherwidget-io {
    width: 50%;
    top: 70px;
  }
  .hg-footer {
    padding: 0px 50px!important;
  }
}
@media (max-width: 479px) {
  .weatherwidget-io {
    width: 100%;
    top: 0px;
    left: 40px;
    right: 0;
    margin: auto;
  }
  .footer-widget ul {
    width: 100%;
  }
  .hg-footer {
    padding: 0px 50px!important;
  }
}

.between {
  z-index: 2;
  position: absolute;
  margin-top: -140px;
  width: 100%;
  height: 160px;
  -webkit-clip-path: polygon(0 0,100% 20%,100% 100%,0 90%);
  clip-path: polygon(0 0,100% 20%,100% 100%,0 90%);
  transform: scale(-1,1);
  -ms-transform: scale(-1,1);
  -webkit-transform: scale(-1,1);
}

.mydivider {
  text-align: center;
  margin: 20px 0;

    img {
      width: 400px; 
      height: auto;
    }
}

.split-layout {
  display: flex;
  flex-direction: column;
  
  @media screen and (min-width: 30em) {
    flex-direction: row;
    align-items: stretch;
  }
}

.split-layout__item  {
  flex: 1;
  
  @media screen and (min-width: 30em) {
    padding-left: 1em;
    
    &:first-child {
    padding: 0;
    }
  }
}

.split-layout__divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media screen and (min-width: 30em) {
   flex-direction: column; 
  }
}

.split-layout__label {
  padding: 1em;
}

.split-layout__rule {
  flex: 1;
  border-style: solid;
  border-color: rgba(66, 65, 65, 0.3);
  border-width: 1px 0 0 0;
  
  @media screen and (min-width: 30em) {
   border-width: 0 1px 0 0;
  }
}

#weatherWidget .current .currentWeather {
    width: 20%;
}

#footer {
    padding: 70px 0 0 0;
    background-color: #fcfcfc;
    box-shadow: inset 0 20px 50px -20px rgba(0,0,0,0.06);
}

.footer-widget ul {
    margin: 0 0 15px 0;
    padding: 0;
    box-sizing: border-box;
    display: inline-block;
    float: left;
}

.img-bgsource {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.img-container__bg {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  height: 100%;
}

.img-container__bg img {
  height: 100%;
  width: 100%;
}

.hg-about-content {
    width: 100%;
    margin: 0 auto;
}
.hg-about-container h4 {
    padding: 10px 5px;
    line-height: 26px;
}
.hg-about-container {
    margin-top: 100px;
    text-align: left;
    padding-bottom: 35px;
}
.hg-about-container--content {
    padding: 0px 128px 30px 0px;
}
.hg-icon_section {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#f7f7f8));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #f7f7f8 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #f7f7f8 100%);
}
.hg-about-container h3 {
    font-size: 30px;
}
.hg-reviews-boom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 400px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
}
#dot-matrix {
    background: url(http://s14.directupload.net/images/111129/44ga9qid.png);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
    margin: 0 auto;
}
.hg-reviews-boom h3 {
  position: absolute;
  transform: translate3d(0px, -64.0031px, 0px);
  color: #fff;
  top: 400px;
}
.brace {
  width: auto;
  min-width: 35px;
  padding-bottom: 20px;
  font-size: 2em;
  line-height: 2em;
  position: relative;
  text-align: center;
  vertical-align: middle;
  margin: 0 15px 15px;
  border: none;
  background-color: transparent;
  background-image: 
    radial-gradient(circle at 0 0, rgba(red,0) 14.5px, red 15.5px, #bcbcbc 19.5px, rgba(#bcbcbc,0) 20.5px),
    radial-gradient(circle at 35px 0, rgba(#bcbcbc,0) 14.5px, #bcbcbc 15.5px, #bcbcbc 19.5px, rgba(#bcbcbc,0) 20.5px);
  background-size: 35px 20px;
  background-position: center bottom;
  background-repeat: no-repeat;
  text-transform: lowercase;
  font-style: italic;
  color: white;
  filter: drop-shadow(0 1px 1px rgba(black,.15));
  overflow: visible;
}
.brace:before {
  width: 50%;
  border-top: 5px solid white;
  border-left: 1px solid transparent; /* play with this until you like the look of it */
  border-top-left-radius: 20% 30px;
  height: 100%;
  content: "";
  
  box-sizing: border-box;
  margin-top: -5px;
}
.brace:after {
  width: 50%;
  border-top: 5px solid white;
  border-right: 1px solid transparent; /* play with this until you like the look of it */
  border-top-right-radius: 20% 30px;
  height: 100%;
  content: "";
  
  box-sizing: border-box;
  margin-top: -5px;
}
.signature img {
  width: auto;
  height: 80px;
}

#page_wrapper {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    z-index: 0;
}
.site-logo {
    width: 100%;
    margin: 0;
    display: table;
    margin: 0 40px;
    position: relative;
}
.useful-links ul {
	li{
		display: block;
		margin: 0 10px;
		position: relative;
    text-decoration:none;
	}
  a {
    color: #535353;
  }
}
ul {
  li{
    display: inline-block;
    margin: 0 10px;
    position: relative;
  }
}
/* helpfull clases*/
.flex {
    display: flex;
    flex-wrap: wrap;
}
.flex-row {
  display: flex;
  flex-direction: row;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
}
.flex-column {
  display: flex;
  flex-direction: column;

}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}

.flex-start-x {
  justify-content: flex-start;
}
.flex-center-y {
    align-items: center;
}
.flex-col {
    flex: 1;
}
.flex-center-x {
   justify-content: center;
}
.flex-end-x {
    justify-content: flex-end;
}
.flex-col-end {
    align-self: flex-end;
}
.flex-basis-auto {
  flex-basis: auto;
}
.flex-grow-0 {
  flex-grow: 0;
}
.pgb-100 {
  padding-bottom:100px;
}
.pgt-100 {
  padding-top:100px;
}
.mgt-60 {
  margin-top: 60px;
}
.mgb-60 {
  margin-bottom: 60px;
}
.mgt-120 {
  margin-top: 120px;
}
.bg-color--white {
  background-color: #fff;
}
.bg-color--grey {
  background-color: #f5f5f5;
}
.overlay--opacity {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #000;
  opacity: .2;
}
.txt-center {
  text-align:center;
}
h1 {
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 20px;
  font-family:$defaultFont;
}
h3 {
	font-family: $titleFont;
	font-size: 24px;
	font-weight: 700;
	color: $titleColor;
	text-transform: uppercase;
}

h4 {
	font-family: $titleFont;
	font-size: 18px;
	font-weight: 400;
	color: $titleColor;
	font-style: normal;
	margin-bottom: 10px;
}

a:hover {
	color: $hoverColor;
	text-decoration: none;
}


span {
  color: $hoverColor;
}

th, td {
    text-align: center;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 35px;
}

/* checkout table*/
.hg-booking {
  margin-bottom: 30px;
  border-spacing:0;
  border-collapse:collapse;
  th {
    color: #2a2a2a;
    padding: 10px 17px;
    text-align: center;
  }
  td {
    padding: 40px 17px;

  }
  &__checkin {
    @include max_desktop {
      display:none;
    }
  }
  &__delete {
    @include max_mobile {
      padding:10px 2px!important;
    }
  }
  &__qty, &__capacity {
    @include max_mobile {
      padding:10px 0px!important;
    }
  }
  &__checkout {
    @extend .hg-booking__checkin;
  }
  &__night {
    @extend .hg-booking__checkin;
  }
}
.hg_subtotal{
  td {
    text-align: left;
  }
  &__value {
    float:right;
    color: $cataloguetitle;
    font-weight: 700;
    margin: -10px 0;
    padding: 10px 0 0 26px;
    border-left: 1px solid #eee;
    text-align: right;
  }
}
.hg_grand_total {
  td {
      text-align: left;
  }
  &__value {
      @extend .hg_subtotal__value;
  }
}
.hg-align-right {
  text-align:right;
}
.hg_tax td,.hg_adv-pay td {
  text-align: left;
}
.hg_tax__value, .hg_adv-pay__value {
  float:right;
  color: $cataloguetitle;
  margin: -10px 0;
  padding: 10px 0 0 26px;
  border-left: 1px solid #eee;
  text-align: right;
}
.hg_payment_all {
  label {
    text-align: right;
    float: right;
    position: relative;
  }
}
.delete_cart {
  color:#555;
}
button {
  height: 50px;
	outline: none;
	border: none;
	text-decoration: none;
	text-transform: uppercase;
	padding: 0px 30px;
	border-radius: 3px;
  -webkit-transition: all 0.3s;
-moz-transition: all 0.3s;
-o-transition: all 0.3s;
-ms-transition: all 0.3s;
transition: all 0.3s;
}

.btn-fullwhite {
    font-family: $titleFont;
    background:none;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;

}
.btn-fullblack {
    font-family: $titleFont;
    background:none;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    letter-spacing: 1px;
    text-transform: uppercase;

}
.hb_btn.box-shadow {
  -webkit-box-shadow: 0px 11px 27px 0px rgba(154, 49, 188, 0.33);
  box-shadow: 0px 11px 27px 0px rgba(154, 49, 188, 0.33);
}
.hg-gallery-but {
  width: 100%;
  margin-top: 30px;
  padding: 10px 20px;
  background: white;
  @include max_desktop {
    margin-top: 60px;
  }
}

input[type=text],input[type=email] {
	max-width: 100%;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	margin-bottom: 15px;
  border: 0px;
	padding: 26px 16px 6px;
}

//LABEL POSITION
label {
    color: $formColor;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    cursor: text;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
    //left: 30px;
    margin-top: 6px;
}
input[type=password] {
	max-width: 100%;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	margin-bottom: 15px;
  border: 0px!important;
	padding: 26px 16px 6px;
}
textarea {
	border: 0px;
  border-radius: 4px;
  padding: 26px 16px 6px;
  margin-bottom: 15px;
  width: 100%;
}

/* Search Page */
.btn_add_to_cart {
      font-family: $titleFont;
      background: $turqoise;
      padding: 10px 25px;
      color: #FFF;
      box-shadow: 0px 10px 25px 0px rgba(0,0,0,0.08);
      border-radius: 3px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 1px;
}
.btn_add_to_cart:hover {
    opacity: 0.8;
}

input[type=checkbox] {
    cursor: pointer;
    text-align: center;
    height: 14px;
    width: 14px;
    min-width: 14px;
    transition: .05s border-color ease-in-out;
    outline: 0!important;
    -webkit-appearance: none;
    border: 1px solid #333;
    margin-top: 3px;
}
input[type=checkbox]:before {
  //TODO GLIPHICONS
    //font-family: 'glyphicons_halflingsregular'!important;
    //content: '\e013';
    font-family: fontAwesome!important;
    content: '';
    display: none;
    color: $check;
}
input[type=checkbox]:checked:before {
  margin-top: -5px;
  float: left;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  font: 400 20px/1 dashicons;
  speak: none;
  -webkit-font-smoothing: antialiased;

}
