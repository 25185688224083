#map {
    height: 600px;
}
.hg-contact {
  background-color: $grey;
  padding: 100px 0px;
  .hg-title-block {
    padding-bottom: 60px;
    &__title {
      font-size: 30px;
    }
  }

  .hg-form-component {
    width:100%;
    label {
      left:30px;
      top: 2px;
      z-index: 99;
    }
    input[type=text], input[type=email] {
      width: 100%;
    }
    .btn-gradient {
      padding: 0px 55px;
      width:auto;
      @include min-desktop {
        margin-left:70px;

      }
    }
  }
}
.js-disable-action {pointer-events: none;}
.js-cf-message {
    display: none;
    border-left: solid 3px transparent;
    padding-left: 5px;
}
.js-response-success {border-left-color: #9bf442 ;}
.js-response-error {border-left-color: #dd0000 ;}
