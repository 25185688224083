.hg-customer {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 25px;
  border-spacing:0;
  border-collapse:collapse;

  .form-group {
    margin-bottom: 25px;
    padding-bottom: 20px;
    span {
      color: red;
      vertical-align: middle;
      margin: 0 10px;
    }
  }
  select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    height:auto;
  }
  input {
    border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 6px 12px;
  }
  .radio {
    padding: 20px 0px;
     label {
      margin-left:0;
      font-weight: 800;
      color: #555;
      text-transform: inherit;
      font-size: 12px;
    }
  }
  textarea {
    border: 1px solid #ccc;
  }
  label {
    margin-top: -22px;
    left: 0;
    margin-left: 50px;
  }
  td {
    border:none;
    padding-bottom: 0;
    @include max_tablet {
      float:left;
      width:100%;
    }

  }
  p {
    text-align: left;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .hg-tdcheck_right {
    position:relative;
    vertical-align: top;
    label {
      margin-left: 35px;
    }
  }
}
.terms_check {
  width:100%;
  position:relative;
  label {
    margin-left:0px;
    font-size: 12px;

    text-transform: inherit;
    a {
      color: #555;
    }

  }
  input[type=checkbox] {
        margin-right: 20px;
  }
}
.checkout-but {
  .btn_add_to_cart {
    margin: 50px 0px;

  }
}
.hg-check {
  font-family:$defaultFont;
  margin: 0 0 15px;
  font-size: 40px;
  line-height: 40px;
  text-transform: inherit;
}
