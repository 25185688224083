.sidebar {
  border: 1px solid $separator;
  overflow: hidden;
  padding: 20px 15px;
  border-radius: 3px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 10px 10px -10px rgba(0,0,0,.15);
  &--room {

  }
  h2 {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 16px;
    color: rgba(0,0,0,0.6);
  }
  .btn-sidebar {
    background: #8973F4;
    font-weight: 400;
    font-family:$defaultFont;
    font-weight: 500;
    font-size: 13px;
  }
  .btn-sidebar:hover {
    background: #2a2a2a;
        opacity:1;

  }
  .btn-gradient {
    margin-top:20px;
  }
  .btn_add_to_cart {
    width:100%;
    margin-bottom: 20px;
    text-transform: inherit;

  }
}

.hg-widget {
  margin-bottom:30px;
  h3 {
    font-family: $btnFont;
    margin-top: 20px;
    font-size: 18px;
    color: #222;
    font-style: normal;
    font-weight: 400;
  }
  &--minicart {
    text-align:center;
    margin: 20px 0 0;
  }
  h4 {
    text-align:left;
    float:left;
    font-size: 16px;
    font-weight: 500;
    width:65%;
  }
  label {
    font-weight: 400;
    font-size: 15px;
    margin-right: 12px;
    margin-bottom: 0;
  }
  span {

    line-height: 37px;
    font-weight: 600;
    margin-left: 80px;
  }
  a {
    float:right;
    line-height: 35px;
    color: #555;
  }
  .minicart-qty {
    span {
      color:#555;
    }
  }
  .minicart-price {
    span {
      color:$turqoise;
    }
  }
}
