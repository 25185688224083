.flex-row.form-row {
  @include max_tablet {
    display: block;
  }
}
.hg-form-component-input {
  margin: 0 auto;
  padding:0 5px;
  margin-bottom: 20px;
  .input-group.date {
    border-bottom: 2px solid #E5E5E5;


  }
  .input-group.date:hover {
    border-bottom: 2px solid black;

  }
  .input-group.date:after {
    position: absolute;
    content: '\f073';
    color: #aaa;
    font: normal normal normal 14px/1 FontAwesome;
    right: 22px;
    top: 12px;
    @media (min-width: 768px) and (max-width: 992px){
          top: 17px;
          right: 9px;
    }
  }
  .input-group {
    height: 50px;
    display: inline-flex;
    width: 100%;
    input[type=text] {
      @extend select;
      border-bottom: 2px solid #E5E5E5;
      border-radius: 0;
    }
  }

  label {
    display: block;
    color: #222;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
    font-size: 13px;
    position: relative;
  }
}
.hg-form-component {
  margin: 0 auto;
  padding:0 5px;
  margin-bottom: 20px;
  display: flex;
  float: left;
  margin-left: 20px;
  //responsiveness
  @include max_mobile {
    margin-top:20px;
  }
}
select {
 text-overflow: ellipsis;
 font-family: sans-serif;
 background: none;
 width: 100%;
 height: 50px;
 border: 0px;
 border-bottom: 2px solid $border;
 min-width: 0;
 padding: 7px 8px;
 //margin-bottom: 15px;
 font-size: 15px;
 color: $selectColor;
 line-height: 24px;
 box-shadow: inset 0 0 0 rgba(0,0,0,.075);
 transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
select:focus, select:hover {
   border-bottom: 2px solid #222;
   border-radius: 0px;
   outline: none;
 }
select:visited, select:active {
   outline: none;
 }
 .btn-gradient {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    background: -webkit-gradient(linear, left top, right top, from(#7f82f0), to(#5ad2d4));
    background: -webkit-linear-gradient(left, #7f82f0 0%, #5ad2d4 100%);
    background: linear-gradient(to right, #7f82f0 0%, #5ad2d4 100%);
    -webkit-box-shadow: 0px 8px 27px 0px rgba(126, 117, 130, 0.33);
    box-shadow: 0px 8px 25px 0px rgba(142, 134, 144, 0.33);
    color: #FFF;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: center;
    width: 100%;
    padding: 0px 28px;
    @media screen and (min-width:991px) and (max-width:1366px) {
      padding: 0px 0px;
    }
  }
 
 .btn-gradient:hover {
   opacity: 0.8;

 }
.search-container {
  h2 {
    margin-bottom: 10px;
    font-size: 16px;
    padding-top: 10px;
}}
.form-container {
      display: block;
      position: relative;
      width: 100%;
      min-height: 0px;
    //  margin-top: -49px;
      background-color: #ffffff;
      padding:35px;
      box-shadow: 0 15px 75px 0 rgba(0,0,0,.06);

}
.home-container {
  margin-top: -130px;
}
/* date picker style */
.ui-datepicker.ui-widget {
    z-index: 9999;
    display: none;
    position: relative;
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 5px 15px -5px rgba(0,0,0,.5);
    border-radius: 0;
    padding: 8px;
}

.ui-datepicker.ui-widget .ui-datepicker-header {
    background: 0 0;
    border: 0
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next,.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev {
    background: center center no-repeat;
    background-size: 75% 75%;
    border: 0;
    cursor: pointer;
    width: 20px;
    height: 30px;
    opacity: .5;
    filter: alpha(opacity=50);
    transition: all .3s;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next span,.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev span {
    display: none;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next:hover,.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-prev {
    float: left;
    left: 0;
    right: auto;
    top: 4px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==);
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-next {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=);
    float: right;
    right: 0;
    top: 4px;
    left: auto;
}

.ui-datepicker.ui-widget .ui-datepicker-header .ui-datepicker-title {
    color: #2a2a2a;
    padding: 5px 3px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    span {
      color: #2a2a2a;
    }
}

.ui-datepicker.ui-widget .ui-datepicker-calendar {
    border: 0;
    margin-bottom: 24px;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar th {
    color: #999;
    font-size: 12px;
    line-height: 25px;
    font-weight: 700;
    text-align: center;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar th span {
    cursor: help;
    border-bottom: 1px dotted #777;
    color: #999;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td {
    padding: 0;
    border: 0;
    height: 27px;
    overflow: hidden;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td.ui-state-disabled {
    padding: 0;
    border: 0;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td.ui-state-disabled .ui-state-default:hover {
    background: #f5f5f5;
    color: #666;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar .ui-state-default {
    cursor: pointer;
    display: block;
    outline: 0;
    border: 0;
    margin: 0;
    width: 100%;
    padding: 5px;
    color: #666;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    background: #f5f5f5;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar .ui-state-default:hover {
    color: #fff;
    background: #ffb606;
}
/* searchroom page */
.hg-searchroom {
  overflow:hidden;
  h2 {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 16px;
    color: rgba(0,0,0,0.6);
    line-height: 40px;
    font-style: normal;
    font-weight: 400;
  }
  .btn-gradient {
    width:auto;
    margin-top: 24px;
  }
}
