.hg-gallery {
  padding-bottom: 20px;

  /*inner column */
  &__photo-column {
    display:flex;
    justify-content: center;
    padding-top:80px;
    @include max_mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
/*big picture from first gallery */
.gridGallery--cols3 {
  .gridGallery__item-w2 {
      width:66.6666%;
      float:left;
      height:100%;
      @include max_mobile {
            width:100%;
            margin-bottom: 10px;
      }
      @include  tablet_desktop {
            width:50%;
      }
    }
  }
  /*second gallery*/
  .gridGallery--cols2 {
    /*small pictures*/
    .gridGallery__item {
        width:50%;
        position: relative;
        float:left;
        @include max_mobile {
          width:100%;
          margin-bottom: 10px;
        }
        @include  tablet_desktop {
          width:50%;
          height:100%;
        }
    }
    /*big picture*/
    .gridGallery__item-w2 {
        width:100%;
        float:left;
        position: relative;
        height: 270px;
        img {
          transition: transform 0.7s linear, -webkit-transform 0.7s linear;
        }
        @include tablet_desktop {
          width:50%;
          height: 100%;
        }
        @media screen and (min-width:991px) and (max-width:1366px) {
          height: 180px;
        }
    }
    /*small picture wrapper*/
    .hg-gridGallery__wrapper {
        padding-bottom: 46%;
        @include  max_mobile {
          padding-bottom: 49%;
        }
        @include tablet_desktop {
          padding-bottom: 69%;
          .hg-gridGallery__img-container {
            img {
              width:150%;
            }
          }
        }
      }
    /*big picture wrapper*/
    .hg-gridGallery__wrapper.hg-gridGallery__item-h1 {
       padding-bottom: 22%;
       margin-top: 20px;
       @include max_mobile {
        margin-top: 15px;
       }
       @media screen and (min-width:991px) and (max-width:1366px) {
          padding-bottom: 21%;
       }
       @include tablet_desktop {
         padding-bottom: 62%;
        }
       .img-container {
         overflow: hidden;
         margin: 0 auto;
         position: absolute;
         top: -200px;
         right: 15px;
         left: 15px;
         @include max_mobile {
          top:0px;
         }
         @include  max_desktop {
           top:0px;
         }
         img {
           width: 100%;
           height: 100%;
           @include max_mobile {
             width: auto;
             height: 100%;
           }
           @include  max_desktop {
             width: 110%;
           }
         }
         @include max_mobile {
           height: 100%;
         }
       }
     }
  }
/*first gallery general styles*/
.hg-gridGallery {
  align-self: center;
  margin: auto;
  width: 80%;
  @include max_mobile {
    height: auto;
    width: 95%;
  }
  &--item {
    position: relative;
    width:33.3333%;
    float:left;
    height:100%;
    @include max_mobile {
      width:100%;
      margin-bottom: 10px;
    }
    @include  tablet_desktop {
      width:50%;
    }
  }
  &__img-container {
    overflow: hidden;
    margin: 0 auto;
    position: absolute;
    top: 15px;
    right: 15px;
    left: 15px;
    img {
      width: 100%;
      height: 100%;
      transition: transform 0.7s linear;
      @include max_mobile {
        width: auto;
        height: 100%;
      }
    }
    @include max_mobile {
      height: 100%;
    }
  }
  &--image_link {
    &:hover {
      .hg-icon {
        display: block;
        opacity: 1;
        margin-top: 0;
      }
      img {
        transform: scale(1.1);
        transition-duration: 2s;
				transition: transform 0.7s linear;
      }
    }
  }
  &__wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 72%;
    @include max_mobile {
      height: 250px;
      overflow: visible;
      padding: 0px 15px 30px 15px;
    }
    .hg-icon {
      opacity: 0;
      position: absolute;
      top: 50%;
      margin-top: 15px;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: all .25s ease-in-out;
      border-color: #fff;
      color: #fff;
      &__circled-icon {
        width: 56px;
        height: 56px;
        line-height: 56px;
        display: inline-block;
        border-radius: 50%;
        box-sizing: content-box;
        text-align: center;
        border: 2px solid currentColor;
        &:before {
          line-height: 56px;
          display: block;
          vertical-align: middle;
        }
      }
    }

  }
}
//slick arrows
.hg-gridGallery {
    &--arrow {
      width: 28px;
      height: 28px;
      cursor: pointer;
      position: absolute;
      top: 46%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    polyline {
        transition: all .15s ease-out;
    }
    polyline:focus {
        outline: -webkit-focus-ring-color auto 5px;
    }
    &:hover polyline{
      stroke-width: 35;
    }
    }
}
.arrow--next {
  left: 100%;
  margin-left: 20px;
}
.arrow--prev {
  right: 100%;
  margin-right: 20px;
}
//Text and button bellow gallery
.hg-gallery-container {
  @extend .hg-wcome-container;
  h3 {
    font-size:30px;
  }
  h4 {
    padding:10px 5px;
    line-height: 26px;
  }
}
.hg-gallery-content{
  @extend .hg-wcome-content;
}
.hg-gallery {
	background-color: #fff;
	&--row {
		width:79%;
		background-color: $separator;
		height:auto;
		padding-bottom: 50px;
		margin-bottom: 60px;
    @include max_tablet {
      margin-bottom: 100px;
    }
	}

	&--text-inner-content{
		vertical-align: middle;
		text-align:center;
		height: auto;
		width:100%;
	}
	@include e(but) {
		background: white;
		width:100%;
		margin-top: 30px;
	}
  &--row-title {
    align-self: center;
    margin: auto;
    width:79%;
    margin-top: 35px;
    @include max_desktop {
      width:85%;
    }
    @include max_mobile{
      width:100%;
    }

  }
}
.hg-title-block {
		&__subtitle {
      font-size: 14px;
      line-height: 26px;
	  }
}
