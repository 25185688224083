.hg-room-details {

}
.hg-tabs {
  clear: both;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;
  &--room {
    background: white;
    z-index: 4;
    position:relative;
    padding-top:12px;
    padding-bottom: 2px;
  }
  li {
    a {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 13px;
      color: #5a5a5a;
      position: relative;
      display: block;
      line-height: 30px;
      padding-bottom: 9px;
    }
    a.active:after {
      background: $turqoise;

    }
    a:after {
      content: '';
      bottom: -2px;
      height: 4px;
      background: transparent;
      left: 0;
      right: 0;
      width: 100%;
      position: absolute;
    }
    a.active {
      color: #2a2a2a;
      text-decoration:none;
    }
  }


}
.hg-tabs-content {
  padding: 60px;
  background: white;
  box-shadow: 0px 15px 76px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 50px;
  @include max_tablet {
    padding: 5px;
  }
  .tabcontent {
    display: none;
    span {
      color:$templateColor;
    }

  }
  .table-hover {
    tr {
      th,td {
        border-left:none;
        border-right:none;
        @include max_tablet {
          padding: 5px;
        }
      }
    }
  }
  &--icon {
    color: $hoverColor!important;
    font-size: 30px;
    line-height: 50px;
    margin-left: 11px;
    margin-right: 23px;
  }
}
